interface ProgressStepsProps {
    activeIndex: number;
    totalSections: number;
}

const COLORS = ["var(--color-status-1)",
    "var(--color-status-2)",
    "var(--color-status-3)",
    "var(--color-status-4)",
    "var(--color-status-5)",
    "var(--color-status-6)"];

const ProgressSteps: React.FC<ProgressStepsProps> = ({ activeIndex, totalSections }) => {

    const sections = Array.from({ length: totalSections }, (_, index) => index);

    return (
        <div className="presence-sections">
            {sections.map((index) => <div
                key={index}
                className="presence-section"
                style={{
                    backgroundColor: index <= activeIndex && (COLORS[index] || COLORS[0])
                }}
            />)}
        </div>
    );

};

export default ProgressSteps;
