import { getMediaUrl } from "@/utils";
import React from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholder?: string
    directSrc?: string
}

const Image: React.FC<ImageProps> = ({ placeholder, directSrc, ...props }) => {

    const resolvedSrc = directSrc || getMediaUrl(props.src, placeholder);

    return (
        <img
            {...props}
            src={resolvedSrc}
        />
    );

};

export default Image;
