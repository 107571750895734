import { useAppSelector } from "@/store";
import { Link } from "react-router";
import GlobalSearch from "./GlobalSearch";

const Breadcrumbs: React.FC = () => {

    const breadcrumbs = useAppSelector((state) => state.app.breadcrumbs);

    return (
        <div className="header__group-search">
            {breadcrumbs.length > 1
                ? <ul className="breadcrumbs">
                    {
                        breadcrumbs.map((breadcrumb) => {

                            if (breadcrumb) {

                                const { path, name } = breadcrumb;
                                return (
                                    <li key={path}>
                                        <Link to={path}>{name}</Link>
                                    </li>
                                );

                            }
                            return null;

                        })
                    }
                </ul>
                : <>
                    <h5 className="header__title">{breadcrumbs[0]?.name || "Dashboard"}</h5>
                    <GlobalSearch />
                </>
            }
        </div>
    );

};

export default Breadcrumbs;
