
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select, { OptionCustomProps } from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { departmentOptions, statusOptions } from "@/constants/options";
import { professionsApi, useGetAllProfessionsQuery } from "@/services/professionsApi";
import { UserFormRequest } from "@/validation/userSchema";
import { UseFormReturn } from "react-hook-form";


interface UserDetailsProps {
    formState: UseFormReturn<UserFormRequest>
}

const UserDetails = ({ formState }: UserDetailsProps) => {
    const { data: response } = useGetAllProfessionsQuery()

    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

    const handleDepartamentChange = (option: OptionCustomProps<number>) => {

        setValue("departmentId", option.value as UserFormRequest["departmentId"]);

    };
    const handleStatusChange = (value: string) => {

        setValue("status", value as UserFormRequest["status"]);

    };
    const handleProfessionChange = (option: OptionCustomProps<string>) => {
        setValue("profession", option.value as UserFormRequest["profession"]);
    };


    const professionOptions: OptionCustomProps<string>[] = response?.data?.map(profession => ({ label: profession.name, value: profession.name })) || []

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Photo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("photo", file)}
                            value={watch("photo") as File | string}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">First Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("firstName")}
                            placeholder="Enter last name..."
                            error={errors.firstName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Last Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("lastName")}
                            placeholder="Enter last name..."
                            error={errors.lastName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">User Role</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={professionOptions}
                            onChange={handleProfessionChange}
                            value={watch("profession")}
                            error={errors.profession?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Department</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={departmentOptions}
                            onChange={handleDepartamentChange}
                            value={watch("departmentId")}
                            error={errors.departmentId?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserDetails;
