

import DownloadIcon from "@/assets/img/ico-download.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import BoardIcon from "@/assets/img/icon-board-18x18.svg?react";
import clsx from "clsx";
import { format } from "date-fns";

import userImage from "@/assets/img/img-user-1.png";
import ActivityChart from "@/components/charts/ActivityChart";
import DoughnutChart from "@/components/charts/DoughnutChart";
import DropdownActions from "@/components/common/DropdownActions";
import WidgetCard from "@/components/cards/WidgetCard";
import { toCurrency } from "@/utils";


const Overview = () => {

    const phases = [{
        name: "Customer Agreement",
        timestamp: "2024-11-28T14:22:12.696Z",
        isMain: true
    },
    {
        name: "consultation completed",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Design retainer signed",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Project scope and budget defined",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Design Proposals",
        timestamp: "2024-11-28T14:22:12.696Z",
        isMain: true
    },
    {
        name: "Initial design concepts created",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Client feedback session held",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Final design approved by client",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Deposit Payment collected upon client approval of concept",
        timestamp: "2024-11-28T14:22:12.696Z"
    },
    {
        name: "Project Development",
        timestamp: "2024-11-28T14:22:12.696Z"
    }];
    return (
        <div className="block-container tab-body-fullpage">
            <div className="row row-statistic overflow-auto">
                <div className="col-xl-4 col-lg-12 col-md-12 col-12 flex flex-col statistic-navbar">

                    <div className="card-widget w-full h-full card-widget-scroller">
                        <div className="card-widget-header">
                            <div className="card-widget-header-item">
                                <h5 className="heading card-widget-title btn btn--xs">Project Phases</h5>
                            </div>
                            <div className="card-widget-header-item">
                                <div className="card-widget-actions">
                                    <a href="#" className="btn btn--secondary-advance-1 btn--xs radius-full">
                                        <span className="ico"><StarsIcon /></span>
                                        <span>Ask Your COO</span>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="card-widget-body">
                            <ul className="boards-list">

                                {phases.map((item, index) => <li key={index} className={clsx("board-item", { "board-item-category": item.isMain })}>
                                    <div className="board-group">
                                        <p className="board-title">{item.name}</p>
                                        <div className="board-date-group">
                                            <div className="icon-board-18x18 color-status-1">
                                                <BoardIcon />
                                            </div>
                                            <span>{format(item.timestamp, "dd.M.yyyy")}</span>
                                        </div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-12col-md-12 col-12 flex flex-col statistic-toolbar">
                    <div className="row gutters-3">
                        <div className="col-xl-6 col-lg-6 col-md-12 flex flex-col">
                            <Activity />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 flex flex-col">
                            <Budget />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 flex flex-col">
                            <Tasks />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 flex flex-col">
                            <Documents />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

};

function Activity () {

    const data = [{
        Mon: 25
    },
    {
        Tue: 50
    },
    {
        Wed: 75
    },
    {
        Thu: 100
    },
    {
        Fri: 125
    }];
    return (
        <WidgetCard title="Activity" subtitle={(<p className="chart-range-dates">01 Oct - 05 Oct</p>)}>
            <div className="card-details-wrpr btn btn--xs">
                <ActivityChart
                    units="%"
                    data={data}
                    maskDayOfWeek="EEE"
                />
            </div>
        </WidgetCard>
    );

}

function Budget () {

    const data = {
        labels: ["Expenses", "Payments Received"],
        data: [25000, 10000],
        format: (val: number) => toCurrency(val)
    };

    const total = {
        label: "Total Budget",
        value: 50000,
        format: (val: number) => toCurrency(val)
    };

    return (
        <WidgetCard title="Budget" showMore>
            <DoughnutChart data={data} total={total} />
        </WidgetCard>
    );

}

function Tasks () {

    return (
        <WidgetCard title="Tasks" showAdd showMore>
            <ul className="projects-list">
                {Array.from({ length: 3 }).map((_, index) => <li key={index} className="project-item">
                    <div className="fake-btn">
                        <div className="project-link btn">
                            <div className="project-link-content">
                                <p className="project-link-title">Some text for the task title</p>
                                <p className="project-link-desk">Deadline: 01.02.2024</p>
                            </div>
                            <div className="user-list-wrpr">
                                <ul className="user-list">
                                    <li className="user-item">
                                        <img src={userImage} alt="user" />
                                    </li>
                                    <li className="user-item">
                                        <img src={userImage} alt="user" />
                                    </li>
                                    <li className="user-item">
                                        <img src={userImage} alt="user" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>)}
            </ul>
        </WidgetCard>
    );

}

function Documents () {

    return (
        <WidgetCard title="Documents" showAdd showMore>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Modern Kitchen Remodel Concept</span>
                                        </a>
                                        <p className="color-control-2 text--sm font-400">01.02.2024</p>
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="actions-table">
                                        <a href="#" download className="btn btn btn--square btn--xs btn-download">
                                            <span className="ico"><DownloadIcon /></span>
                                        </a>
                                        <DropdownActions
                                            actions={[{
                                                icon: <EditIcon />,
                                                label: "Edit",
                                                fn: () => console.log("Edit")
                                            }]}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}


export default Overview;
