import { useState, useEffect } from "react";
import { format, addDays, startOfWeek, getDay } from "date-fns";
import clsx from "clsx";

interface ActivityChartProps {
    units?: string;
    maskDayOfWeek?: string;
    data: Record<string, number | undefined>[];
}

const ActivityChart = ({
    data = [],
    units = "%",
    maskDayOfWeek = "EEE"
}: ActivityChartProps) => {

    const [dataActivities, setDataActivities] = useState<Record<string, number>[]>([]);
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {

        const currentDate = new Date();
        const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
        const currentDayOfWeek = getDay(currentDate) > data.length || getDay(currentDate) === 0
            ? data.length + 1
            : getDay(currentDate);

        const normalizedData = data.map((entry) => {

            const normalizedEntry: Record<string, number> = {};
            Object.keys(entry).forEach((key) => {

                normalizedEntry[key] = entry[key] ?? 0; // Ensure no `undefined` values

            });
            return normalizedEntry;

        });

        setDataActivities(normalizedData);

        const totalProgress = normalizedData.reduce((total, entry) => {

            const dailySum = Object.values(entry).reduce((dayTotal, value) => dayTotal + value, 0);
            return total + dailySum;

        }, 0);

        const centerData = Math.floor(totalProgress / currentDayOfWeek);
        setProgress(isNaN(centerData)
            ? 0
            : centerData);

    }, [data, maskDayOfWeek]);

    const renderBar = (data: Record<string, number>) => Object.entries(data).map(([key, value]) => <div
        className="canva-bar"
        style={{
            "--filled-value-prc--str": `${data[key]}${units}`,
            "--filled-value-prc--nmb": `${data[key]}${units}`
        } as React.CSSProperties}
        key={key}
    >
        <div className="canva-bar-content">
            <p className="canva-bar-day-of-week">{key}</p>
            <p className="canva-bar-values">{value + " " + units}</p>
        </div>
    </div>);

    return (
        <div className="table-custom table-custom-2">
            <div className="table-custom-body">
                <div className="fake-bar-chart bar-fax-wrapper--js">
                    <div className="fake-bar-chart--header">
                        <div className="progress-chart--wrpr data-progress-week">
                            <span className="progress-chart--val">{progress}</span>
                            <span className="progress-chart--prc">{units}</span>
                        </div>
                    </div>
                    <div className="fake-bar-chart--body">
                        <div className="activities-canvas canva-bar-153">
                            <div className="activities-body">
                                <ul className="activities-bars bar-fax-js">
                                    {dataActivities.map((data, index) => {

                                        const day = Object.keys(data)[0];
                                        const daysIterator = index + 1;

                                        let className = "";
                                        if (getDay(new Date()) > daysIterator) {

                                            className = "--past-date";

                                        } else if (getDay(new Date()) < daysIterator) {

                                            className = "--perfect-date";

                                        } else {

                                            className = "--target-date";

                                        }

                                        return (
                                            <li key={day} className={clsx("activity-bar", className)}>
                                                {renderBar(data)}
                                            </li>
                                        );

                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ActivityChart;
