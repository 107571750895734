import ChevronRihgtIcon from "@/assets/img/chevron-right-icon.svg?react";
import Button from "@/components/common/Button";
import { VoidFunctionWithoutArguments } from "@/types";
import { useCallback, useState } from "react";
import OptionSelector from "./OptionSelector";
import PlusIcon from "@/assets/img/plus.svg?react";
import DocumentIcon from "@/assets/img/ico-doc-csv.svg?react";

interface OptionSelectionProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleContinueClick: (isCsvLoading?: boolean) => void;
}

const OptionSelection: React.FC<OptionSelectionProps> = ({
    handleCancel,
    handleContinueClick
}) => {

    const [selectedOption, setSelectedOption] = useState<0 | 1>(0);

    const handleManuallyClick = useCallback(() => {

        setSelectedOption(0);

    }, []);

    const handleUploadClick = useCallback(() => {

        setSelectedOption(1);

    }, []);

    const handleContinue = useCallback(() => {
        handleContinueClick(selectedOption === 1);
    }, [selectedOption]);

    return (
        <div className="p-4">
            <h4 className="heading flex-auto text-center p-2 mb-4">Select Option</h4>

            <div className="px-4 mb-5 tabs-container">
                <OptionSelector
                    isActive={selectedOption === 0}
                    icon={<PlusIcon />} text={"Create manually"}
                    handleClick={handleManuallyClick}
                />
                <OptionSelector
                    isActive={selectedOption === 1}
                    icon={<DocumentIcon />} text={"Upload CSV"}
                    handleClick={handleUploadClick}
                />
            </div>

            <div className="modal__footer justify-center">
                <Button onClick={handleCancel} className="btn btn--md btn--custom-1 rounded-full mx-2">Cancel</Button>
                <Button
                    type="button"
                    className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class"
                    onClick={handleContinue}
                >
                    <span>Continue</span>
                    <span className="ico"><ChevronRihgtIcon /></span>
                </Button>
            </div>
        </div>
    );

};

export default OptionSelection;
