import Button from "@/components/common/Button";
import Input from "@/components/common/Input";

const PasswordSecurity = () => {

    return (
        <>
            <div className="tab-body__item-border">
                <div className="tab-body__item">
                    <div className="settings-item-group">
                        <div className="settings-item__label-group">
                            <label className="settings-item__label">Change Password</label>
                            <p className="settings-item__descripton">Securing your account, change your password</p>
                        </div>
                        <div className="settings-item__options">
                            <div className="row gx-16 gy-18">
                                <div className="col-12">
                                    <Input
                                        label="Current Password"
                                        placeholder="Current Password"
                                        onChange={() => { }}
                                        value={"Current Password"}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input
                                        label="New Password"
                                        placeholder="New Password"
                                        onChange={() => { }}
                                        value={"New Password"}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input
                                        label="First Name"
                                        placeholder="First Name"
                                        onChange={() => { }}
                                        value={"First Name"}
                                    />
                                </div>
                                <div className="col-12 flex justify-end">
                                    <Button className="btn--primary btn--md radius-full">Change Password</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-body__item">
                <div className="settings-item-group">
                    <div className="settings-item__label-group">
                        <label className="settings-item__label">Two-factor authentication (2FA)</label>
                        <p className="settings-item__descripton">Manage your company information</p>
                    </div>
                    <div className="settings-item__options">
                        <div className="switch-control-item">
                            <label className="switch-control-wrapper">
                                <label className="switch-control gap-20">
                                    <input type="checkbox" hidden checked />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <div className="switch-text-col">
                                        <span className="settings-item__label">Text Message (SMS) </span>
                                        <p className="settings-item__descripton">Receive a one-time passcode via SMS each time you login.</p>
                                    </div>
                                </label>
                            </label>
                        </div>

                        <div className="switch-control-item">
                            <label className="switch-control-wrapper">
                                <label className="switch-control gap-20">
                                    <input type="checkbox" hidden />
                                    <div className="switch-btn">
                                        <div className="switch-point-wrapp">
                                            <span className="switch-point"></span>
                                        </div>
                                    </div>
                                    <div className="switch-text-col">
                                        <span className="settings-item__label">Authenticator App (TOTP)</span>
                                        <p className="settings-item__descripton">Use an app to receive temporary one-time passcode each time you login.</p>
                                    </div>
                                </label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default PasswordSecurity;
