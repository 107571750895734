import React from "react";

import integrationLogo1 from "@/assets/img/integration-logo-1.svg";
import integrationLogo2 from "@/assets/img/integration-logo-2.png";
import integrationLogo3 from "@/assets/img/integration-logo-3.png";
import integrationLogo4 from "@/assets/img/integration-logo-4.png";
import integrationLogo5 from "@/assets/img/integration-logo-5.png";

const Integrations = () => {

    return (
        <>
            <div className="tab-body__item">
                <div className="settings-item-group">
                    <div className="settings-item__label-group">
                        <label className="settings-item__label">Integrations</label>
                        <p className="settings-item__descripton">Connect KBF with the tools you already use</p>
                    </div>
                    <div className="settings-item__options">
                        <div className="integrations">
                            <div className="integration">

                                <div className="integration__logo-box">
                                    <img src={integrationLogo1} alt="integrationLogo1" />
                                </div>
                                <div className="integration__name-group">
                                    <div className="flex items-center gap-10">
                                        <h6 className="integration__name">QuickBooks</h6>
                                        <span className="pill pill-status">Connected</span>
                                    </div>
                                    <p className="settings-item__descripton">Connect KBF with the tools you already use</p>
                                </div>

                                <label className="switch-control-wrapper">
                                    <label className="switch-control">
                                        <input type="checkbox" hidden checked />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                    </label>
                                </label>
                            </div>
                            <div className="integration">

                                <div className="integration__logo-box">
                                    <img src={integrationLogo2} alt="integrationLogo2" />
                                </div>
                                <div className="integration__name-group">
                                    <h6 className="integration__name">Google Drive</h6>
                                    <p className="settings-item__descripton">Easily store and access project files in Google Drive.</p>
                                </div>

                                <label className="switch-control-wrapper">
                                    <label className="switch-control">
                                        <input type="checkbox" hidden />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                    </label>
                                </label>
                            </div>
                            <div className="integration">

                                <div className="integration__logo-box">
                                    <img src={integrationLogo3} alt="integrationLogo3" />
                                </div>
                                <div className="integration__name-group">
                                    <h6 className="integration__name">Slack</h6>
                                    <p className="settings-item__descripton">Get real-time project updates and notifications.</p>
                                </div>

                                <label className="switch-control-wrapper">
                                    <label className="switch-control">
                                        <input type="checkbox" hidden />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                    </label>
                                </label>
                            </div>
                            <div className="integration">

                                <div className="integration__logo-box">
                                    <img src={integrationLogo4} alt="integrationLogo4" />
                                </div>
                                <div className="integration__name-group">
                                    <h6 className="integration__name">Dropbox</h6>
                                    <p className="settings-item__descripton">Store large project files and share them with your team.</p>
                                </div>

                                <label className="switch-control-wrapper">
                                    <label className="switch-control">
                                        <input type="checkbox" hidden />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                    </label>
                                </label>
                            </div>
                            <div className="integration">

                                <div className="integration__logo-box">
                                    <img src={integrationLogo5} alt="integrationLogo5" />
                                </div>
                                <div className="integration__name-group">
                                    <h6 className="integration__name">Asana</h6>
                                    <p className="settings-item__descripton">Sync tasks and milestones with Asana.</p>
                                </div>

                                <label className="switch-control-wrapper">
                                    <label className="switch-control">
                                        <input type="checkbox" hidden />
                                        <div className="switch-btn">
                                            <div className="switch-point-wrapp">
                                                <span className="switch-point"></span>
                                            </div>
                                        </div>
                                    </label>
                                </label>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );

};

export default Integrations;
