import React from "react";

const NotFound = () => {

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col text-center mt-5">
                <h1>404</h1>
                <p>Page is not found</p>
            </div>
        </main>
    );

};

export default NotFound;
