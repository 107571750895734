import NextIcon from "@/assets/img/ico-next.svg?react";
import PrevIcon from "@/assets/img/ico-prev.svg?react";
import { Pagination } from "@/types";

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel as getSorted,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import clsx from "clsx";
import { Fragment, useState } from "react";
import Select from "./Select";
import { useSearchParams } from "react-router";

interface TableProps<T> extends TableFooterProps {
    columns: ColumnDef<T, any>[],
    data: T[],
    renderAccordionContent?: (row: T) => JSX.Element
    selectable?: boolean
    hideSelectAll?: boolean
    hideFooter?: boolean
}


const Table = <T,>({ columns, data, renderAccordionContent, selectable, hideSelectAll, hideFooter, ...props }: TableProps<T>) => {

    const [sorting, setSorting] = useState<SortingState>([]);
    const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean }>({});
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const { getHeaderGroups, getSortedRowModel } = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSorted(),
        state: {
            sorting,
            columnPinning: {
                right: ["actions"]
            }
        },
        onSortingChange: setSorting
    });

    const toggleRowSelection = (rowId: string) => {

        if (!selectable) return;
        setSelectedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId]
        }));

    };

    const toggleAllRows = () => {

        if (!selectable) return;
        const allSelected = Object.keys(selectedRows).length === data.length;
        const newSelectedState = allSelected
            ? {}
            : Object.fromEntries(getSortedRowModel().rows.map((row) => [row.id, true]));
        setSelectedRows(newSelectedState);

    };

    const isRowSelected = (rowId: string) => !!selectedRows[rowId];
    const toggleRowExpansion = (rowId: string) => {

        setExpandedRow((prev) => (prev === rowId ? null : rowId));

    };

    return (
        <div className="table-custom">
            <div className="table-custom-body">
                <table className="table" cellPadding="0" cellSpacing="0">
                    <thead className="table-sticky-head">
                        {getHeaderGroups().map((headerGroup) => <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => <th
                                className={clsx({
                                    "--sort": header.column.getCanSort(),
                                    "--asc": header.column.getIsSorted() === "asc",
                                    "--desc": header.column.getIsSorted() === "desc",
                                    "--dop-class-last-sticky": header.column.getIsPinned()
                                })}
                                // style={{ minWidth: header.column.getSize() }}
                                key={header.id}
                                colSpan={header.colSpan}
                            >
                                {!header.isPlaceholder &&
                                    <div className="table-select-container">
                                        {index === 0 && selectable && !hideSelectAll &&
                                            <label className="check-wrapper table-gap-x">
                                                <input type="checkbox"
                                                    hidden
                                                    onChange={toggleAllRows}
                                                    checked={
                                                        Object.keys(selectedRows).length === data.length &&
                                                        data.length > 0
                                                    }
                                                />
                                                <span className="check check--xxs check-custom-2"></span>
                                            </label>
                                        }
                                        <p
                                            className="th-text"
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </p>
                                    </div>
                                }
                            </th>)}
                        </tr>)}
                    </thead>
                    <tbody>
                        {getSortedRowModel().rows.length === 0 ? (
                            <tr>
                                <td colSpan={columns.length}>
                                    <h5 className="heading mt-5 text-center">No items</h5>
                                </td>
                            </tr>
                        ) :
                            getSortedRowModel().rows.map((row) =>
                                <Fragment key={row.id}>
                                    <tr
                                        onClick={() => toggleRowExpansion(row.id)}

                                    >
                                        {row.getVisibleCells().map((cell, index) => <td
                                            key={cell.id}
                                            style={{
                                                cursor: renderAccordionContent
                                                    ? "pointer"
                                                    : "default",
                                                width: cell.column.getIsPinned()
                                                    ? "0px"
                                                    : "auto"
                                            }}
                                            className={clsx({
                                                "--dop-class-last-sticky": cell.column.getIsPinned()
                                            })}
                                        >
                                            <div
                                                className="table-select-container"
                                            >
                                                {index === 0 && selectable &&
                                                    <label
                                                        className="check-wrapper table-gap-x"
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            hidden
                                                            checked={isRowSelected(row.id)}
                                                            onChange={() => toggleRowSelection(row.id)}
                                                        />
                                                        <span className="check check--xxs check-custom-2"></span>
                                                    </label>

                                                }
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        </td>)}
                                    </tr>
                                    {expandedRow === row.id && renderAccordionContent && renderAccordionContent(row.original)}
                                </Fragment>)
                        }
                    </tbody>
                </table>
            </div>
            {
                !hideFooter && <TableFooter {...props} />
            }

        </div>
    );

};


export interface TableFooterProps {
    pagination?: Pagination;
    onPageChange?: (page: number) => void;
    onLimitChange?: (limit: number) => void;
}


export function TableFooter({
    pagination = {
        page: 1,
        limit: 10,
        offset: 0,
        maxPages: 1,
        total: 1
    },
    onPageChange,
    onLimitChange
}: TableFooterProps) {

    const [searchParams, setSearchParams] = useSearchParams();

    const limits = [{
        label: "10",
        value: 10
    }, {
        label: "20",
        value: 20
    }, {
        label: "50",
        value: 50
    }];

    const handlePageChange = (page: number) => {

        setSearchParams((prev) => {

            prev.set("page", String(page));
            return prev;

        });
        if (onPageChange) {

            onPageChange(page);

        }

    };

    const handleLimitChange = (limit: number) => {

        setSearchParams((prev) => {

            prev.set("limit", String(limit));
            return prev;

        });
        if (onLimitChange) {

            onLimitChange(limit);

        }

    };
    return (
        <div className="table-custom-footer">
            <div className="table-text-footer justify-start">
                <div className="btn btn--xs p-0 border-none">
                    <p className="text--sm font-400">Showing</p>
                </div>
                <Select
                    wrapperClassName="select--outline input--xs"
                    options={limits}
                    value={pagination.limit}
                    onChange={(option) => handleLimitChange(Number(option.value))}
                />
                <div className="btn btn--xs p-0 border-none">
                    <p className="text--sm font-400">of <span>{pagination.total}</span> materials</p>
                </div>
            </div>
            <div className="pagination-wrpr">
                <button
                    type="button"
                    className={`btn btn--xs btn--square btn--custom-1 rounded-full ${pagination.page === 1
                        ? "--disabled-pagination-btn"
                        : ""}`}
                    onClick={() => handlePageChange(pagination.page - 1)}
                >
                    <span className="ico"><PrevIcon /></span>
                </button>
                <ul className="pagination-list">
                    {[...Array(pagination.maxPages).keys()].map((num) => <li key={num + 1} className="pagination-item">
                        <a
                            href="#"
                            className={`btn btn--square btn-pagination ${pagination.page === num + 1
                                ? "--active-page"
                                : ""}`}
                            onClick={() => handlePageChange(num + 1)}
                        >
                            {num + 1}
                        </a>
                    </li>)}
                </ul>
                <button
                    type="button"
                    className={`btn btn--xs btn--square btn--custom-1 rounded-full ${pagination.page === pagination.maxPages
                        ? "--disabled-pagination-btn"
                        : ""}`}
                    onClick={() => handlePageChange(pagination.page + 1)}
                >
                    <span className="ico"><NextIcon /></span>
                </button>
            </div>
            <div className="table-text-footer justify-end">
                <div className="btn btn--xs p-0 border-none">
                    <p className="text--sm font-400">Go to page</p>
                </div>
                <div className="form-group input--xs">
                    <input
                        className="input input--outline input-count-page"
                        type="number"
                        value={pagination.page}
                        onChange={(e) => handlePageChange(Number(e.target.value))}
                    />
                </div>
            </div>
        </div>
    );

}


export default Table;
