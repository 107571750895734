import { useTabs } from "@/hooks/useTabs";
import { useCallback, useEffect } from "react";

import CloseIcon from "@/assets/img/close-icon.svg?react";
import StepsNavigation from "@/components/common/StepsNavigation";
import EntityFooter from "@/components/modals/NewEntityModal/EntityFooter";
import { VoidFunctionWithoutArguments } from "@/types";
import { Status } from "@/types/emums";
import { projectSchema } from "@/validation/projectSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Contract from "../tabs/Contract";
import Description from "../tabs/Description";
import Photos from "../tabs/Photos";
import ProjectDetails from "../tabs/ProjectDetails";
import Location from "../tabs/Location";
import { normalizePhone, objectToFormData } from "@/utils";
import { useCreateProjectMutation, useUpdateProjectMutation } from "@/services/projectsApi";

interface ProjectDataProps {
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
}

const tabs = [{
    label: "Project Details",
    id: "details",
    fields: ["cover",
        "type",
        "name",
        "deadline",
        "estVolume",
        "departmentId",
        "status"]
},
{
    label: "Description",
    id: "description",
    fields: []
},
{
    label: "Location",
    id: "location",
    fields: []
},
{
    label: "Photos",
    id: "photos",
    fields: []
},
{
    label: "Contract",
    id: "contract",
    fields: []
}] as const;
type TabId = typeof tabs[number]["id"];


const ProjectData: React.FC<ProjectDataProps> = ({
    handleCancel,
    handleCompleteClick
}) => {
    const [create, { isLoading: isCreating }] = useCreateProjectMutation()
    const [update, { isLoading: isUpdating }] = useUpdateProjectMutation()
    const isLoading = isCreating || isUpdating
    const { activeTab, setActiveTab, activeTabIndex, destroy } = useTabs(tabs, "mt");

    const currentSchema = projectSchema.pick(tabs[activeTabIndex].fields);
    const resolver = yupResolver(currentSchema);
    const formState = useForm({
        resolver,
        defaultValues: {
            status: Status.ACTIVE
        }
    });
    const { register, handleSubmit, formState: { errors }, reset } = formState;


    const onSubmit = async (data: any) => {

        console.log("Form data:", data);
        if (activeTabIndex < tabs.length - 1) {

            setActiveTab(tabs[activeTabIndex + 1].id);

        } else {
            const normalizedData = { ...data, phone: normalizePhone(data.phone) }
            const res = await create(normalizedData).unwrap()
            if (res?.success) {
                handleCompleteClick();
            }
            console.log("Final Submit:", data);

        }

    };

    useEffect(() => {

        return () => destroy();

    }, []);

    useEffect(() => {

        console.log("errors", errors);

    }, [errors]);


    const tabComponents: Record<TabId, JSX.Element | null> = {
        details: <ProjectDetails formState={formState} />,
        description: <Description formState={formState} />,
        location: <Location formState={formState} />,
        photos: <Photos formState={formState} />,
        contract: <Contract formState={formState} />
    };

    const handleGoBack = useCallback(() => {

        setActiveTab(tabs[activeTabIndex - 1].id);

    }, [activeTabIndex]);

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal__header">
                <h4 className="heading flex-auto">New Project</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="modal__body pt-0">
                <div className="w-full">
                    <StepsNavigation
                        activeStep={activeTab}
                        setActiveStep={setActiveTab}
                        steps={tabs}
                    />
                    <div className="modal-tabs">
                        {renderTab()}
                    </div>
                </div>
            </div>
            <EntityFooter
                isLoading={isLoading}
                handleGoBack={handleGoBack}
                handleCancel={handleCancel}
                completeText="Complete"
                nextStepText="Next Step"
                isFirstStep={activeTab === "details"}
                isFinalStep={activeTab === "contract"}
            />
        </form>
    );

};

export default ProjectData;
