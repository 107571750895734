import { LoginRequest, PaginationQuery } from "@/types/request";
import type { ResponseType, User } from "../types";
import { baseApi } from "./baseApi";
import { objectToFormData, objectToQueryString } from "@/utils";
import { UserFormRequest } from "@/validation/userSchema";


const base = "api/users";

export const usersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query<
            ResponseType<User>,
            void
        >({
            query: (query) => `${base}/me`,
            providesTags: ["me"]
        }),
        getAllUsers: builder.query<
            ResponseType<User[]>,
            PaginationQuery | void
        >({
            query: (query) => `${base}?${objectToQueryString(query)}`,
            providesTags: ["users"]
        }),
        updateMe: builder.mutation<
            ResponseType<User>,
            UserFormRequest
        >({
            query: (body) => ({
                url: `${base}`,
                method: "PUT",
                body: objectToFormData(body)
            }),
            invalidatesTags: ["me"]
        }),
        createUser: builder.mutation<
            ResponseType<User>,
            UserFormRequest
        >({
            query: (body) => ({
                url: `${base}`,
                method: "POST",
                body: objectToFormData(body)
            }),
            invalidatesTags: ["users"]
        }),
        updateUser: builder.mutation<ResponseType<User>, 
        { userId: number, body: UserFormRequest }>({
            query: ({ userId, body }) => ({
                url: `${base}/${userId}`,
                method: "PUT",
                body: objectToFormData(body)
            }),
            invalidatesTags: ["users"]})

    })
});

export const {
    useGetMeQuery,
    useUpdateMeMutation,
    useGetAllUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
} = usersApi;
