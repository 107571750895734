
import BoxIcon from "@/assets/img/ico-box.svg?react";
import DocumentIcon from "@/assets/img/ico-doc.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import projectImage from "@/assets/img/project-img-1.jpg";

import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";

interface OrderData {
    orderNumber: string;
    materialName: string;
    supplier: string;
    orderDate: string;
    quantity: string;
    status: string;
    deliveryDate: string;
    project: string;
}

interface MaterialOrdersTabProps {
    isGridView: boolean
    isTableView: boolean
}
const MaterialOrdersTab = ({ isGridView, isTableView }: MaterialOrdersTabProps) => {

    const data: OrderData[] = [{
        orderNumber: "#MO-001",
        materialName: "Oak Hardwood Flooring",
        supplier: "ABC Building Supplies",
        orderDate: "Oct 15, 2024",
        quantity: "150 sq. ft.",
        status: "Delivered",
        deliveryDate: "Oct 15, 2024",
        project: "Beach House Remodel"
    }];

    const actions = (arg: OrderData) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    const columns: ColumnDef<OrderData>[] = [{
        header: "Order #",
        accessorKey: "orderNumber",
        cell: ({ row }) => <div className="table-select-container">
            <label className="check-wrapper table-gap-x">
                <input type="checkbox" name="check" hidden />
                <span className="check check--xxs check-custom-2"></span>
            </label>
            <p className="color-control-3">{row.original.orderNumber}</p>
        </div>

    },
    {
        header: "Material Name",
        accessorKey: "materialName",
        cell: ({ row }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{row.original.materialName}</span>
        </a>

    },
    {
        header: "Supplier",
        accessorKey: "supplier",
        cell: ({ row }) => <p className="color-control-3">{row.original.supplier}</p>
    },
    {
        header: "Order Date",
        accessorKey: "orderDate",
        cell: ({ row }) => <p className="color-control-3">{row.original.orderDate}</p>
    },
    {
        header: "Quantity",
        accessorKey: "quantity",
        cell: ({ row }) => <p className="color-control-3">{row.original.quantity}</p>
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{row.original.status}</span>
        </div>

    },
    {
        header: "Delivery Date",
        accessorKey: "deliveryDate",
        cell: ({ row }) => <p className="color-control-3">{row.original.deliveryDate}</p>
    },
    {
        header: "Project",
        accessorKey: "project",
        cell: ({ row }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{row.original.project}</span>
        </a>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            actions={actions(original)}
            variant="secondary"
        />

    }];

    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                #MO-001
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text --style-2">
                                                        Oak Hardwood Flooring
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <BoxIcon />
                                                    </span>
                                                    <p className="project-variable--text">ABC Building Supplies</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <DocumentIcon />
                                                    </span>
                                                    <p className="project-variable--text">Quantity:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">150 sq. ft.</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>Delivered</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">

                                        <ul className="project-list user-noname-desk contacts-list">
                                            <li className="project-list-item">
                                                <a href="#" className="btn btn-link color-secondary tdu">
                                                    <span className="font-400 text--sm">Modern Kitchen Remodel</span>
                                                </a>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Order Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">Oct 15, 2024</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Delivery Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">Oct 15, 2024</p>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><EditIcon /></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    return <Table columns={columns} data={data} selectable />;

};

export default MaterialOrdersTab;
