import AddIcon from "@/assets/img/ico-add.svg?react";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { routerConfig } from "@/routes";

import EditIcon from "@/assets/img/ico-edit.svg?react";
import CSVIcon from "@/assets/img/csv-icon.svg?react";

import user1 from "@/assets/img/user-1.png";
import user2 from "@/assets/img/user-img.png";


import DropdownActions from "@/components/common/DropdownActions";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import Select from "@/components/common/Select";
import Table from "@/components/common/Table";
import { statusMap, projectStatusMap, USER_ROLE } from "@/constants";
import { User } from "@/types";
import { getFullName, getFullUrl } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { Link } from "react-router";
import Button from "@/components/common/Button";
import { useGetAllUsersQuery } from "@/services/usersApi";
import PageLoader from "@/components/layout/PageLoader";
import { useQueryParams } from "@/hooks/useQueryParams";
import useModal from "@/hooks/useModal";


const UsersPage = () => {

    useBreadcrumbs([routerConfig.users]);
    const { limit, page } = useQueryParams();
    const { data: response, isLoading } = useGetAllUsersQuery({
        limit,
        page
    });
    const { openModal } = useModal();

    const actions = (arg: User) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => openModal('editUser', { data: arg }),
    }];

    const roleOptions = [{
        label: "",
        value: ""
    }];

    if (isLoading) {

        return <PageLoader />;

    }

    const columns: ColumnDef<User>[] = [{
        header: "User Name",
        accessorKey: "firstName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info-texts">
            <Link to={getFullUrl([routerConfig.team.path, original.id, original.id])} className="btn btn-link color-secondary tdu">
                <span className="text--md font-400">{getFullName(original)}</span>
            </Link>
        </div>

    },
    {
        header: "Email",
        accessorKey: "email",
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.email}</span>
        </a>

    },
    {
        header: "Role",
        accessorKey: "profession",
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-3 pill--sm">
            <span>{original.profession}</span>
        </div>

    },
    {
        header: "Team(s)",
        accessorKey: "teams",
        cell: ({ row: { original } }) => <span>0</span>


    },
    {
        header: "Projects",
        accessorKey: "projects",
        cell: ({ row: { original } }) => <span>0</span>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", statusMap['active'].className)}>
            <span>{statusMap[original.status].label}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];
    const data = response.data;
    const pagination = response.pagination;

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <Filters />
                                <Search />
                                <div className="flex-controls gap--xs">
                                    <Select options={roleOptions} placeholder="Role Type" wrapperClassName="w-200" />
                                </div>
                            </div>
                            <div className="heading-option">
                                <div className="flex-controls gap--xs">
                                    <Button className="btn btn--custom-1 btn--md rounded-full">
                                        <span className="ico"><CSVIcon /></span>
                                        <span>Import</span>
                                    </Button>
                                    <Button onClick={() => openModal("newUser")} className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><AddIcon /></span>
                                        <span>New User</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className="view-page-block tabs-fullpage active-page" >
                            <div className="tab active-tab" data-id="total-projects">
                                <Table columns={columns} data={data} pagination={pagination} selectable />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );

};

export default UsersPage;
