import { Document, VoidFunctionWithoutArguments } from "@/types";
import EntityFooter from "./EntityFooter";
import CloseIcon from "@/assets/img/close-icon.svg?react";
import DragFileInput from "@/components/common/DragFileInput";
import Divider from "@/components/common/Divider";
import { useCallback, useState } from "react";
import DocumentsTable from "@/components/common/DocumentsTable";
import EditIcon from "@/assets/img/ico-edit.svg?react";

const actions = (arg: Document) => [{
    label: "Edit",
    icon: <EditIcon />,
    fn: () => console.log(arg)
}];

interface UploadCsvProps {
    title?: string;
    exampleFileLink?: string;
    handleGoBack: VoidFunctionWithoutArguments;
    handleCancel: VoidFunctionWithoutArguments;
    handleCompleteClick: VoidFunctionWithoutArguments;
}

const UploadCsv: React.FC<UploadCsvProps> = ({
    title = 'Upload CSV file',
    exampleFileLink = '@/assets/csvExample.csv',
    handleGoBack,
    handleCancel,
    handleCompleteClick,
}) => {
    const [files, setFiles] = useState<FileList | null>(null);

    const handleUpload = useCallback((uploadedFiles: FileList) => {
        setFiles(uploadedFiles)
    }, []);

    const getDataForTable = useCallback((uploadedFile: File) => {
        return [{
            id: 1,
            name: uploadedFile.name
        }]
    }, []);

    return (
        <div>
            <div className="modal__header">
                <h4 className="heading flex-auto">{title}</h4>
                <div className="modal__header-actions">
                    <button type="button" onClick={handleCancel} className="btn btn--custom-1 btn--md rounded-full btn--square">
                        <span className="ico"><CloseIcon /></span>
                    </button>
                </div>
            </div>

            <div className="p-4">
                <DragFileInput 
                    isMultiple={false}
                    externalHandler={handleUpload}
                />

                {files && 
                    <DocumentsTable 
                        data={getDataForTable(files[0])} 
                        actions={actions}
                    />
                }

                <Divider />

                <p className="text-400">
                    Please use
                    &nbsp;
                    <a 
                        href={exampleFileLink} 
                        download={'example.csv'} 
                        className="tdu color-secondary"
                    >
                        Example File
                    </a>
                    &nbsp;
                    to upload proper CSV file.
                </p>
            </div>

            <EntityFooter
                isLoading={false}
                handleGoBack={handleGoBack}
                handleCancel={() => {}}
                onSubmit={handleCompleteClick}
                completeText="Complete"
                nextStepText="Next Step"
                isFirstStep={false}
                isFinalStep={true}
            />
        </div>
    )
}

export default UploadCsv
