import StarsIcon from "@/assets/img/ico-stars.svg?react";
import useModal from "@/hooks/useModal";
import clsx from "clsx";


interface AssistantButtonProps {
    text?: string
    onClick?: () => void
}

const AssistantButton = ({ text, onClick }: AssistantButtonProps) => {

    const { openModal } = useModal();

    const handleOpenAssistant = () => {

        if (onClick) {

            onClick();

        }
        openModal("assistent");

    };
    return (
        <button
            onClick={handleOpenAssistant}
            className={clsx("btn btn--secondary-advance-1 btn--xs rounded-full", {
                "btn--square": !text
            })}
        >
            <span className="ico"><StarsIcon /></span>
            {text}
        </button>
    );

};

export default AssistantButton;
