import { LoginRequest, PaginationQuery } from "@/types/request";
import type { Client, ResponseType, User } from "../types";
import { baseApi } from "./baseApi";
import { objectToFormData, objectToQueryString } from "@/utils";
import { UserFormRequest } from "@/validation/userSchema";
import { ClientFormRequest } from "@/validation/clientSchema";


const base = "api/clients";

export const clientsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getClientById: builder.query<
            ResponseType<Client>,
            { clientId: string }
        >({
            query: ({ clientId }) => `${base}/${clientId}`,
        }),
        getAllClients: builder.query<
            ResponseType<Client[]>,
            PaginationQuery | void
        >({
            query: (query) => `${base}?${objectToQueryString(query)}`,
            providesTags: ["clients"]
        }),
        createClient: builder.mutation<
            ResponseType<Client>,
            ClientFormRequest
        >({
            query: (body) => ({
                url: `${base}`,
                method: "Post",
                body: objectToFormData(body)
            }),
            invalidatesTags: ['clients'],
        }),
        updateClient: builder.mutation<
            ResponseType<Client>,
            { clientId: number, body: UserFormRequest }
        >({
            query: ({ clientId, body }) => ({
                url: `${base}/${clientId}`,
                method: "PUT",
                body: objectToFormData(body)
            }),
            invalidatesTags: ['clients'],
        })

    })
});

export const {
    useGetAllClientsQuery,
    useGetClientByIdQuery,
    useCreateClientMutation,
    useUpdateClientMutation
} = clientsApi;
