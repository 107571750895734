

import AddIcon from "@/assets/img/ico-add.svg?react";
import ConceptDesignTeamIcon from "@/assets/img/ico-concept-design-team.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import MailCharIcon from "@/assets/img/ico-mail-char.svg?react";
import MapPinIcon from "@/assets/img/ico-map-pin.svg?react";
import PhoneIcon from "@/assets/img/ico-phone.svg?react";
import UserIcon from "@/assets/img/ico-user-2.svg?react";

import userImage from "@/assets/img/ava-image-2.png";
import DropdownActions from "../common/DropdownActions";


const MemberCard = () => {

    return (
        <div className="card-widget card-widget-product w-full h-full">
            <div className="card-widget-body">
                <div className="card-details-wrpr">
                    <div className="card-details--item">
                        <div className="card-details--head">
                            <div className="user-option-block user-option-block--sm">
                                <div className="user-block-relatives">
                                    <div className="ico ico--lg user-block-img-wrpr rounded-full">
                                        <img src={userImage} alt="user" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-details-actions">
                                <div className="pill-custom pill--custom-11 pill--xs">
                                    <span>Occupied</span>
                                </div>
                                <DropdownActions
                                    actions={[{
                                        icon: <EditIcon />,
                                        label: "Edit info",
                                        fn: () => console.log("Edit")
                                    }]}
                                />
                            </div>
                        </div>
                        <div className="card-details--body">
                            <div className="card-details-heading">
                                <p className="heading user-block-title text--xl">
                                    Team Member Card
                                </p>
                                <span className="color-status-2 text--md">Lead Designer</span>
                            </div>
                            <ul className="project-list user-noname-desk contacts-list">
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <ConceptDesignTeamIcon />
                                        </span>
                                        <p className="project-variable--text">Concept Design Team</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <MapPinIcon />
                                        </span>
                                        <p className="project-variable--text">Los Angeles, CA</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <MailCharIcon />
                                        </span>
                                        <p className="project-variable--text">alma.lawson@example.com</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <PhoneIcon />
                                        </span>
                                        <p className="project-variable--text">(217) 555-0113</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-details--item">
                        <div className="card-details--body">

                            <div className="card-details--projects-info-wrpr">
                                <div className="card-details--head card-details--project-row">
                                    <ul className="counter-projects-list">
                                        <li className="counter-project-item">
                                            <div className="counter-project-text">
                                                <div className="pill-custom pill--xs">1</div>
                                                <p className="counter-project-value">Active Projects</p>
                                            </div>
                                        </li>
                                        <li className="counter-project-item">
                                            <div className="counter-project-text">
                                                <div className="pill-custom pill--xs">3</div>
                                                <p className="counter-project-value">Completed</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-details--projects-info--body">
                                    <ul className="card-details--projects-info-list">
                                        <li className="card-details--project-info--item">
                                            <div className="btn btn--project-row-2">

                                                <div className="project-titled-wrpr">

                                                    <div className="event-project-status --info-4"></div>

                                                    <div className="project-info-wrapper-block">
                                                        <div className="project-info-item-block --only">
                                                            <p className="project-title-4">Team Member Card</p>
                                                            <div className="project-info-date">
                                                                <span className="color-control-2">Due: </span>
                                                                <span className="fw-500">Nov 10, 2024</span>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="card-details--actions">
                                <div className="card-details--action-item">
                                    <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                        <span>View Tasks</span>
                                    </a>
                                </div>
                                <div className="card-details--action-item">
                                    <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                        <span className="ico"><UserIcon /></span>
                                        <span>Profile</span>
                                    </a>
                                </div>
                                <a href="#" className="btn btn--primary-advance-1 ico--md rounded-full btn--dop-class">
                                    <span className="ico"><AddIcon /></span>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default MemberCard;
