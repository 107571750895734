import React from "react";
import clsx from "clsx";
import { Tab } from "@/hooks/useTabs";

interface TabNavigationProps<T> {
    tabs: readonly Tab<T>[];
    activeTab: T;
    setActiveTab: (tabId: T) => void;
    renderTabCount?: (tabId: T) => React.ReactNode;
    variant?: "primary" | "secondary";
}

const TabNavigation = <T, >({
    tabs,
    activeTab,
    setActiveTab,
    renderTabCount,
    variant = "primary"
}: TabNavigationProps<T>) => {

    return (
        <div className="tabs-navigation">
            <div className={clsx("tabs-navigation-list", {
                "--list-2": variant === "primary"
            })}>
                {tabs.map(({ id, label }, index) => <button
                    key={index}
                    onClick={() => setActiveTab(id)}
                    className={clsx("btn btn--md", {
                        "active-tab": activeTab === id,
                        "tab-custom-btn-2": variant == "primary",
                        "tab-custom-btn": variant === "secondary"
                    })}
                >
                    {renderTabCount &&
                            <div className="pill-custom pill--xs">
                                {renderTabCount(id)}
                            </div>
                    }
                    <span>{label}</span>
                </button>)}
            </div>
        </div>
    );

};

export default TabNavigation;
