export enum UserRole {
  ADMIN = "admin",
}

export enum ProjectStatus {
  AGREEMENT = "agreement",
  PROPOSAL = "proposal",
  DEVELOPMENT = "development",
  EXPEDITION = "expedition",
  ROUGH_IN = "rough-in",
  FINISHES = "finishes",
  PUNCH_OUT = "punch-out",
  COMPLETED = "completed",
}

export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum ClientType {
  RESIDENTAL = 'residential'
}

export enum RiskLevel {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high"
}

export enum ContactMethod {
  EMAIL = "email",
  PHONE = "phone",
  SMS = "sms"
}

export enum EntityCreationStages {
    SelectOption = "selectOption",
    FillData = "fillData",
    Complete = "complete",
    UploadCsv = 'uploadCsv'
}
