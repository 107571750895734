import FiltersIcon from "@/assets/img/ico-filters.svg?react";

const Filters = () => {

    return (
        <button className="btn btn--custom-1 btn--md rounded-full btn-filters">
            <span className="ico"><FiltersIcon /></span>
            <span>Filters</span>
        </button>
    );

};

export default Filters;
