import TabNavigation from "@/components/common/TabNavigation";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import { Navigate, useLocation, useParams } from "react-router";


import Overview from "./tabs/Overview";

import FireIcon from "@/assets/img/Fire--Streamline-Bootstrap.svg?react";
import ChevronNextIcon from "@/assets/img/ico-chevron-next-2.svg?react";
import ChevronPrevIcon from "@/assets/img/ico-chevron-prev-2.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";

import projectImage from "@/assets/img/project-img-3.jpg";
import { useGetClientByIdQuery } from "@/services/clientsApi";
import PageLoader from "@/components/layout/PageLoader";
import NotFound from "../NotFound ";
import { formatAddress } from "@/utils";
import Button from "@/components/common/Button";
import AssistantButton from "@/components/common/AssistantButton";


const tabs = [{
    id: "overview",
    label: "Overview"
},
{
    id: "projects",
    label: "Projects"
},
{
    id: "financial-summary",
    label: "Financial Summary"
},
{
    id: "history",
    label: "History"
},
{
    id: "documents",
    label: "Documents"
},
{
    id: "notes",
    label: "Notes"
}] as const;

type TabId = typeof tabs[number]["id"];


const ClientPage = () => {
    const { clientId } = useParams();
    const { data: response, isLoading, isUninitialized } = useGetClientByIdQuery({ clientId })
    const { pathname } = useLocation();
    const { activeTab, setActiveTab } = useTabs(tabs);
    const data = response?.data

    useBreadcrumbs([routerConfig.clients, {
        path: pathname,
        name: data?.fullName
    }]);

    if (isLoading || isUninitialized) {
        return <PageLoader />
    }

    if (!data) {
        return <NotFound />
    }

    const tabComponents: Record<TabId, JSX.Element | null> = {
        overview: <Overview client={data} />,
        projects: null,
        "financial-summary": null,
        history: null,
        documents: null,
        notes: null
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <div className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="block-container">
                    <div className="heading--row">
                        <div className="heading-option">
                            <div className="user-option-block">
                                <div className="ico ico--xl user-block-img-wrpr">
                                    <img src={projectImage} />
                                </div>
                                <div className="user-block-content-info">
                                    <div className="user-block-titled-info">
                                        <h5 className="heading user-block-title">{data.fullName}</h5>
                                        <span className="ico user-ico-hot">
                                            <FireIcon />
                                        </span>
                                    </div>
                                    <div className="user-block-desk-info">
                                        <p className="user-location--text">{formatAddress(data.address)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="heading-option">
                            <div className="flex-controls gap--xs">
                                <Button className="btn btn--primary-advance-1 btn--md rounded-full">
                                    <span className="ico"><AddIcon /></span>
                                    <span>New Project</span>
                                </Button>
                                <Button className="btn btn--custom-1 btn--md rounded-full">
                                    <span className="ico"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00573 2.83521H4.21536C3.85236 2.83521 3.50423 2.9794 3.24756 3.23608C2.99088 3.49276 2.84668 3.84089 2.84668 4.20388V13.7846C2.84668 14.1476 2.99088 14.4957 3.24756 14.7524C3.50423 15.0091 3.85236 15.1533 4.21536 15.1533H13.7961C14.1591 15.1533 14.5072 15.0091 14.7639 14.7524C15.0206 14.4957 15.1648 14.1476 15.1648 13.7846V8.99425" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M13.3683 2.57851C13.6406 2.30627 14.0098 2.15332 14.3948 2.15332C14.7798 2.15332 15.1491 2.30627 15.4213 2.57851C15.6936 2.85076 15.8465 3.22001 15.8465 3.60502C15.8465 3.99004 15.6936 4.35928 15.4213 4.63153L9.00567 11.0472L6.26831 11.7315L6.95265 8.99419L13.3683 2.57851Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    </span>
                                    <span>Edit</span>
                                </Button>
                            </div>
                            <span className="option-separator"></span>
                            <div className="flex-controls gap--xs">
                                <AssistantButton />
                                <Button className="btn btn--custom-1 btn--md rounded-full btn--square">
                                    <span className="ico">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1909 12.4411C14.265 11.3077 13.6112 10.7308 13.6112 7.60638C13.6112 4.74521 12.1502 3.72587 10.9476 3.23078C10.7879 3.16515 10.6376 3.01443 10.5889 2.85038C10.3779 2.13245 9.78659 1.5 9.00049 1.5C8.21447 1.5 7.62271 2.13283 7.41395 2.85107C7.36529 3.01694 7.21491 3.16515 7.05519 3.23078C5.8512 3.72656 4.39158 4.74232 4.39158 7.60638C4.38979 10.7308 3.73605 11.3077 2.81008 12.4411C2.42642 12.9105 2.76248 13.6154 3.43354 13.6154H14.5711C15.2385 13.6154 15.5724 12.9084 15.1909 12.4411Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M11.3081 13.6152V14.1922C11.3081 14.8042 11.065 15.3911 10.6322 15.8239C10.1994 16.2567 9.61241 16.4999 9.00039 16.4999C8.38837 16.4999 7.80135 16.2567 7.36858 15.8239C6.93586 15.3911 6.69269 14.8042 6.69269 14.1922V13.6152" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="tabs tabs-fullpage">
                    <div className="tabs-row-wrpr block-container">
                        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} variant="secondary" />
                        <div className="group-date-controls">
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronPrevIcon /></span>
                            </button>
                            <div className="btn btn--xl date-value-view-wrpr">
                                <span className="date-value-view--text">October 2024</span>
                            </div>
                            <button type="button" className="btn btn--xl btn--square btn--custom-1-trsp btn-date-control">
                                <span className="ico"><ChevronNextIcon /></span>
                            </button>
                        </div>
                    </div>
                    <div className="tabs-body-content">
                        <div className="tab tab--bg active-tab" data-id="tasks">
                            {renderTab()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ClientPage;
