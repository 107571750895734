
import BellIcon from "@/assets/img/Bell--Streamline-Lucide.svg?react";
import CircleHelpIcon from "@/assets/img/Circle-Help--Streamline-Lucide.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import InviteIcon from "@/assets/img/icon-invite-user.svg?react";
import Breadcrumbs from "../../common/Breadcrumbs";
import DropdownProfile from "./DropdownProfile";

const Header = () => {

    return (
        <header className="header">
            <div className="header__wrap">
                <div className="header__container">
                    <div className="header__body">
                        <Breadcrumbs />
                        <div className="header__right-tools">
                            <div className="flex items-center ">
                                <a href="#" className="notification-link ico ico--xs active">
                                    <BellIcon />
                                </a>
                                <a href="#" className="ico ico--xs">
                                    <CircleHelpIcon />
                                </a>
                            </div>
                            <div className="flex items-center gap-8 hidden-420">
                                <button type="button" className="btn btn--custom-1  btn--xs rounded-full nowrap">
                                    <span className="ico">
                                        <InviteIcon />
                                    </span>
                                    <span className="hidden-576">Invite User</span>
                                </button>
                                <button type="button" className="btn btn--secondary-advance-1 ico--md rounded-full">
                                    <span className="ico"><StarsIcon /></span>
                                </button>

                                <button type="button" className="btn btn--primary-advance-1 ico--md rounded-full">
                                    <span className="ico"><AddIcon /></span>
                                </button>

                            </div>
                            <DropdownProfile />
                        </div>
                        <div className="header__burger">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );

};


export default Header;
