import React from "react";
import Spinner from "./Spinner";
import clsx from "clsx";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
    children,
    className = "",
    onClick,
    type = "button",
    loading,
    ...props
}) => {

    return (
        <button
            className={clsx("btn", className, { disabled: props.disabled })}
            type={type}
            onClick={onClick}
            {...props}
        >
            {loading && <div className="btn-loader"><Spinner /></div>}
            {children}
        </button>
    );

};

export default Button;
