import ShopIcon from "@/assets/img/ico-shop.svg?react";
import TeamIcon from "@/assets/img/ico-team.svg?react";
import DropdownActions from "@/components/common/DropdownActions";
import { ColumnDef } from "@tanstack/react-table";

import Table from "@/components/common/Table";

interface TeamPerformanceProps {
	isGirdView: boolean
	isTableView: boolean
}


interface TeamData {
	teamName: string;
	projectsAssigned: number;
	tasksCompletedPercentage: string;
	avgTaskCompletionTime: string;
	activeMembers: number;
	issuesRaised: number;
	status: "On Track" | "Delayed" | "Completed" | "Paused";
}


const TeamPerformance = ({ isGirdView, isTableView }: TeamPerformanceProps) => {

    const teamData: TeamData[] = [{
        teamName: "Company Sales Team",
        projectsAssigned: 8,
        tasksCompletedPercentage: "85%",
        avgTaskCompletionTime: "5 Days",
        activeMembers: 12,
        issuesRaised: 2,
        status: "On Track"
    }];

    if (isGirdView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr rounded-full">
                                                    <span className="ico user-black-img--ico-2">
                                                        <TeamIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Concept Design Team
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>On Track</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Projects Assigned:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">8</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Tasks Completed (%):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">85%</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">
                                                        Avg. Task <br /> Completion Time:
                                                    </p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">5 Days</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Active Members:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500 color-status-6">12</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Issues Raised:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">2</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Show More</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    const columns: ColumnDef<TeamData>[] = [{
        header: "Team",
        accessorKey: "teamName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="category-option category-style-1">
            <span className="ico ico-category">
                <span className="ico">
                    <ShopIcon />
                </span>
            </span>
            <span className="text-category">{original.teamName}</span>
        </div>

    },
    {
        header: "Projects Assigned",
        accessorKey: "projectsAssigned",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.projectsAssigned}</p>

    },
    {
        header: "Tasks Completed (%)",
        accessorKey: "tasksCompletedPercentage",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.tasksCompletedPercentage}</p>

    },
    {
        header: "Avg. Task Completion Time",
        accessorKey: "avgTaskCompletionTime",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.avgTaskCompletionTime}</p>

    },
    {
        header: "Active Members",
        accessorKey: "activeMembers",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.activeMembers}</p>

    },
    {
        header: "Issues Raised",
        accessorKey: "issuesRaised",
        cell: ({ row: { original } }) => <p className="font-500">{original.issuesRaised}</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        minSize: 180,
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-3 pill--sm">
            <span>{original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions actions={[]} variant="secondary" />

    }];

    return <Table columns={columns} data={teamData} selectable />;

};

export default TeamPerformance;
