import AssistantModal from "@/components/modals/AssistantModal";
import TaskModal from "@/components/modals/TaskModal";
import useModal from "@/hooks/useModal";
import EditClientModal from "@/pages/ClientsPage/EditClientModal";
import NewClientModal from "@/pages/ClientsPage/NewClientModal";
import NewProjectModal from "@/pages/ProjectsPage/NewProjectModal";
import EditTeamModal from "@/pages/TeamManagementPage/EditTeamModal";
import NewTeamModal from "@/pages/TeamManagementPage/NewTeamModal";
import EditUserModal from "@/pages/UsersPage/EditUserModal";
import NewUserModal from "@/pages/UsersPage/NewUserModal";
import { useAppSelector } from "@/store";

export type ModalID = "task" | "assistent" | "newClient" | "newUser" | "newProject" | "newTeam" | "editClient" | "editUser" | "editTeam";

export interface ModalProps extends Record<ModalID, any> {
    task: Partial<React.ComponentProps<typeof TaskModal>>;
    assistent: Partial<React.ComponentProps<typeof AssistantModal>>;
    newClient: Partial<React.ComponentProps<typeof NewClientModal>>;
    newUser: Partial<React.ComponentProps<typeof NewUserModal>>;
    newProject: Partial<React.ComponentProps<typeof NewProjectModal>>;
    editClient: Partial<React.ComponentProps<typeof EditClientModal>>;
    newTeam: Partial<React.ComponentProps<typeof NewTeamModal>>;
}

export const modalComponents: Record<ModalID, React.ComponentType<any>> = {
    task: TaskModal,
    assistent: AssistantModal,
    newClient: NewClientModal,
    newUser: NewUserModal,
    newProject: NewProjectModal,
    editClient: EditClientModal,
    editUser: EditUserModal,
    newTeam: NewTeamModal,
    editTeam: EditTeamModal,
};

const ModalProvider = () => {

    const modal = useAppSelector((state) => state.app.modal);
    const { closeModal } = useModal();

    if (!modal) return null;

    const ModalComponent = modalComponents[modal.id];

    return <ModalComponent {...modal.props} isOpen onClose={closeModal} />;

};

export default ModalProvider;
