import MultiChoiceSelector from "@/components/common/MultiChoiceSelector";
import RadioGroup from "@/components/common/RadioGroup";
import { ClientFormRequest } from "@/validation/clientSchema";
import { UserFormRequest } from "@/validation/userSchema";
import { UseFormReturn } from "react-hook-form";

interface PersonalSkillsProps {
    formState: UseFormReturn<UserFormRequest>
}
const PersonalSkills = ({ formState }: PersonalSkillsProps) => {

    const { register, watch, setValue, resetField, formState: { errors, defaultValues } } = formState;

    const skillsOptions = [{ label: "Communication",
        value: "communication" },
    { label: "Team Leadership",
        value: "team_leadership" },
    { label: "Time Management",
        value: "time_management" },
    { label: "Problem Solving",
        value: "problem_solving" },
    { label: "Project Management",
        value: "project_management" },
    { label: "Creativity",
        value: "creativity" },
    { label: "Adaptability",
        value: "adaptability" },
    { label: "Critical Thinking",
        value: "critical_thinking" }];

    const handleSkillsChange = (value: string[]) => {

        setValue("skills", value);

    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <MultiChoiceSelector
                    options={skillsOptions}
                    value={watch("skills") || []}
                    onChange={handleSkillsChange}
                />
            </div>
        </div>
    );

};

export default PersonalSkills;
