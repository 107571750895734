import DatePicker from "@/components/common/DatePickerInline";
import DatepickerOutline from "@/components/common/DatepickerOutline";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import TabNavigation from "@/components/common/TabNavigation";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";
import { useTabs } from "@/hooks/useTabs";
import AddIcon from "@/assets/img/ico-add.svg?react";
import clsx from "clsx";
import ClientCard from "./components/ClientCard";
import { clients, tabFields, tabs } from "./consts";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Client } from "@/types";
import { Link } from "react-router";
import { formatAddress, getFullUrl } from "@/utils";
import { routerConfig } from "@/routes";
import { statusMap } from "@/constants";
import DropdownActions from "@/components/common/DropdownActions";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import ProjectsTable from "./components/ProjectsTable";
import useModal from "@/hooks/useModal";
import Button from "@/components/common/Button";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useGetAllClientsQuery } from "@/services/clientsApi";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import PageLoader from "@/components/layout/PageLoader";
import Image from "@/components/common/Image";

const ClientsPage = () => {
    useBreadcrumbs([routerConfig.clients]);
    const { limit, page } = useQueryParams();
    const { data: response, isLoading } = useGetAllClientsQuery({
        limit,
        page
    });
    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();

    const { openModal } = useModal();

    const actions = (arg: Client) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => openModal('editClient', { data: arg })
    }];

    if (isLoading) {
        return <PageLoader />;

    }

    const columns: ColumnDef<Client>[] = [{
        header: "Client Name",
        accessorKey: "fullName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info">
            <span className="ico ico-ava ico-ava-24">
                <Image src={original.photo} alt={original.fullName} />
            </span>
            <div className="table-user-info-texts">
                <Link to={getFullUrl([routerConfig.clients.path, original.id])} className="btn btn-link color-secondary tdu">
                    <span className="text--md font-400">{original.fullName}</span>
                </Link>
                <p className="color-control-2 text--sm font-400">
                    {formatAddress(original.address, ['city', 'state'])}
                </p>
            </div>
        </div>

    },
    {
        header: "Email",
        accessorKey: "email",
        cell: ({ row: { original } }) => <p className="color-control-3 table-text-nowrap">{original.email}</p>

    },
    {
        header: "Phone",
        accessorKey: "phone",
        cell: ({ row: { original } }) => <p className="color-control-3 table-text-nowrap">{original.phone}</p>

    },
    {
        header: "Active Projects",
        accessorKey: "activeProjects",
        cell: ({ row: { original } }) => <p className="color-control-3">{"TODO"} docs</p>

    },
    {
        header: "Completed",
        accessorKey: "completedProjects",
        cell: ({ row: { original } }) => <p className="color-control-3">{"TODO"}</p>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", statusMap[original.status].className)}>
            <span>{statusMap[original.status].label}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            actions={actions(original)}
        />

    }];

    const data = response.data;
    const pagination = response.pagination;
    return (
        <div className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">

                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <TabNavigation
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                renderTabCount={() => 3}
                            />
                            <div className="heading-option">
                                <ViewModeSwitcher />
                                <span className="option-separator"></span>
                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <Button onClick={() => openModal("newClient")} className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><AddIcon /></span>
                                        <span>New Client</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className={clsx("view-page-block tabs-fullpage active-page")}>
                            {isGridView
                                ? <div className="tab active-tab" data-id="all-clients">
                                    <div className="row gutters-4">
                                        {
                                            data.map((item) => <div key={item.id} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                                                <ClientCard data={item} actions={actions} />
                                            </div>)
                                        }
                                    </div>
                                </div>
                                : <Table columns={columns} data={data} pagination={pagination} renderAccordionContent={(row) => <ProjectsTable />} />}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

};

export default ClientsPage;
