import AppLayout from "@/layouts/AppLayout";
import MainLayout from "@/layouts/MainLayout";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import AccountSettingsPage from "@/pages/AccountSettingsPage";
import BudgetBillingPage from "@/pages/BudgetBillingPage";
import ClientPage from "@/pages/ClientPage";
import ClientsPage from "@/pages/ClientsPage";
import DesignsProposalsPage from "@/pages/DesignsProposalsPage";
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import NotFound from "@/pages/NotFound ";
import ProjectPage from "@/pages/ProjectPage";
import ProjectsPage from "@/pages/ProjectsPage";
import ReportsPage from "@/pages/ReportsPage";
import SchedulingPage from "@/pages/SchedulingPage";
import SuppliersInventoryPage from "@/pages/SuppliersInventoryPage";
import TeamManagementPage from "@/pages/TeamManagementPage";
import TeamPage from "@/pages/TeamPage";
import UserPage from "@/pages/UserPage";
import UsersPage from "@/pages/UsersPage";
import { getFullUrl } from "@/utils";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router";

export const routerConfig = {
    login: {
        path: "/",
        name: "Login"
    },
    dashboard: {
        path: "/dashboard",
        name: "Dashboard"
    },
    projects: {
        path: "/projects",
        name: "Projects"
    },
    clients: {
        path: "/clients",
        name: "Clients"
    },
    designs: {
        path: "/designs",
        name: "Designs & Proposals"
    },
    scheduling: {
        path: "/scheduling",
        name: "Scheduling"
    },
    suppliers: {
        path: "/suppliers",
        name: "Suppliers & Inventory"
    },
    budget: {
        path: "/budget",
        name: "Budget & Billing"
    },
    team: {
        path: "/team",
        name: "Team Management"
    },
    reports: {
        path: "/reports",
        name: "Reports"
    },
    users: {
        path: "/users",
        name: "All Users"
    },
    settings: {
        path: "/settings",
        name: "Account Settings"
    }
};


export const router = createBrowserRouter(createRoutesFromElements(<Route element={<AppLayout />}>
    <Route element={<ProtectedLayout />}>
        <Route path={"/"} element={<LoginPage />} />
        <Route element={<MainLayout />}>
            <Route path={routerConfig.dashboard.path} element={<HomePage />} />
            <Route path={routerConfig.projects.path} element={<ProjectsPage />} />
            <Route path={getFullUrl([routerConfig.projects.path, ":projectId"])} element={<ProjectPage />} />
            <Route path={routerConfig.clients.path} element={<ClientsPage />} />
            <Route path={getFullUrl([routerConfig.clients.path, ":clientId"])} element={<ClientPage />} />
            <Route path={routerConfig.designs.path} element={<DesignsProposalsPage />} />
            <Route path={routerConfig.scheduling.path} element={<SchedulingPage />} />
            <Route path={routerConfig.suppliers.path} element={<SuppliersInventoryPage />} />
            <Route path={routerConfig.budget.path} element={<BudgetBillingPage />} />
            <Route path={routerConfig.team.path} element={<TeamManagementPage />} />
            <Route path={getFullUrl([routerConfig.team.path, ":teamId"])} element={<TeamPage />} />
            <Route path={getFullUrl([routerConfig.team.path, ":teamId", ":userId"])} element={<UserPage />} />
            <Route path={routerConfig.reports.path} element={<ReportsPage />} />
            <Route path={routerConfig.users.path} element={<UsersPage />} />
            <Route path={routerConfig.settings.path} element={<AccountSettingsPage />} />
            <Route path="*" element={<NotFound />} />
        </Route>
    </Route>
</Route>), {
    basename: "/"
});
