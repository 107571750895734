
import Button from "@/components/common/Button";
import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select, { OptionCustomProps } from "@/components/common/Select";
import Table from "@/components/common/Table";
import UploadPhoto from "@/components/common/UploadPhoto";
import { clientTypeOptions, insuranceOptions, riskLevelOptions, statusOptions } from "@/constants/options";
import { TeamMember, User } from "@/types";
import { ClientFormRequest } from "@/validation/clientSchema";
import { TeamFormRequest } from "@/validation/teamSchema";
import { UseFormReturn } from "react-hook-form";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { statusMap } from "@/constants";
import DropdownActions, { DropdownActionsProps } from "@/components/common/DropdownActions";
import { getFullName } from "@/utils";
import { useGetAllUsersQuery } from "@/services/usersApi";
import PageLoader from "@/components/layout/PageLoader";
import { useEffect, useState } from "react";
import Image from "@/components/common/Image";


interface MembersProps {
    formState: UseFormReturn<TeamFormRequest>
}

const Members = ({ formState }: MembersProps) => {
    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;
    const { data: response, isLoading } = useGetAllUsersQuery()
    const [q, setQ] = useState('')
    const [members, setMembers] = useState<User[]>([])
    const users = response?.data

    const handleAddMember = (member: User) => {
        setMembers(prev => ([...prev, member]))
        setQ('')
    }
    const actions = (arg: User) => [{
        label: "Delete",
        icon: '',
        fn: () => setMembers(prev => prev.filter(member => member.id !== arg.id))
    }];

    if (isLoading) {
        return <PageLoader />
    }

    useEffect(() => {

        const parsedMembers: TeamFormRequest['Members'] = members.map(member => ({
            id: member.id,
            profession: member.profession,
            status: member.status
        }))
        console.log(members, parsedMembers);

        setValue('Members', parsedMembers)
    }, [members])


    const filterMember = (member: User) => {
        const alreadyInclude = members.find(({ id }) => id === member.id)
        const byName = getFullName(member).toLocaleLowerCase().includes(q.toLocaleLowerCase())
        const byEmail = member.email.toLocaleLowerCase().includes(q.toLocaleLowerCase())
        return !alreadyInclude && (byName || byEmail)

    }
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row-group gap--xs">
                    <div className="relative w-full">
                        <Input
                            className="input--md flex-auto"
                            placeholder="Invite members by name or email..."
                            onChange={(e) => setQ(e.target.value)}
                            value={q}
                        />
                        {
                            q && (
                                <ul style={{
                                    position: 'absolute',
                                    top: 42,
                                    width: `100%`,
                                    background: 'white',
                                    border: '1px solid var(--color-control-1)',
                                    borderRadius: 12,
                                    minHeight: 42,
                                    zIndex: 99,
                                    cursor: 'pointer'
                                }}>
                                    {users.filter(filterMember).map(user => (
                                        <li onClick={() => handleAddMember(user)}>
                                            <div className="row-group px-3">
                                                <span className="ico ico-ava ico-ava-24">
                                                    <Image src={user.photo} alt={user.firstName} />
                                                </span>
                                                <Button className="btn btn--md color-secondary tdu">
                                                    <span className="text--md font-400">{getFullName(user)}</span>
                                                </Button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )
                        }
                    </div>
                    {/* <Button className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">Invite</Button> */}
                </div>
            </div>
            <div className="col-12">
                <MembersTable data={members} actions={actions} />
            </div>
        </div>
    );

};

type Actions = DropdownActionsProps['actions']

interface MembersTableProps {
    data: User[]
    actions: (arg: User) => Actions
}

function MembersTable({ data, actions }: MembersTableProps) {
    const columns: ColumnDef<User>[] = [{
        header: "User Name",
        accessorKey: "firstName",
        minSize: 228,
        cell: ({ row: { original } }) => <div className="table-user-info-texts">
            <span className="text--md font-400">{getFullName(original)}</span>
        </div>

    },
    {
        header: "Role",
        accessorKey: "profession",
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-3 pill--sm">
            <span className="text--md font-500">{original.profession}</span>
        </div>

    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", statusMap['active'].className)}>
            <span>{statusMap[original.status].label}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];


    return <div className="table-accord"><Table columns={columns} data={data} hideFooter /></div>
}


export default Members;
