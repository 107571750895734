import FileIcon from "@/assets/img/File-Text--Streamline-Lucide.svg?react";
import CloudIcon from "@/assets/img/Message--Streamline-Tabler.svg?react";
import CircleProgressIcon from "@/components/common/CircleProgressIcon";
import useModal from "@/hooks/useModal";
import { Task } from "@/types";

interface TaskCardProps {
    data: Task
}

const TaskCard = ({ data }: TaskCardProps) => {

    const { openModal } = useModal();
    const handleTaskOpen = () => {

        openModal("task", { data });

    };
    return (
        <div onClick={handleTaskOpen} className="card-widget card-widget-task" style={{ cursor: "pointer" }}>
            <div className="card-widget-body">
                <div className="card-details-wrpr">
                    <div className="card-details--item">
                        <div className="card-details--head items-center">
                            <div className="pill-custom pill--custom-9 pill--xs">
                                <span>{data.priority}</span>
                            </div>
                        </div>
                        <div className="card-details--body">
                            <p className="heading user-block-title text--lg">
                                {data.title}
                            </p>
                            <p className="user-role--date">
                                <span className="date-variable-text">Due:</span>
                                <span>{data.dueDate}</span>
                            </p>
                        </div>
                        <div className="card-details--footer">
                            <ul className="details-list-info">
                                <li className="details-list--item">
                                    <CircleProgressIcon progress={5} total={12} />
                                </li>
                                <li className="details-list--item">
                                    <div className="detail-text-wrpr">
                                        <span className="ico detail-text--ico"><FileIcon /></span>
                                        <span className="detail-text-values">
                                            {data.documents}
                                        </span>
                                    </div>
                                </li>
                                <li className="details-list--item">
                                    <div className="detail-text-wrpr">
                                        <span className="ico detail-text--ico"><CloudIcon /></span>
                                        <span className="detail-text-values">
                                            {data.comments}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                            <ul className="user-list">
                                {data.users.map((user, index) => <li key={index} className="user-item">
                                    <img src={user} alt="User" />
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default TaskCard;
