
import BoxIcon from "@/assets/img/ico-box.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import projectImageThumb from "@/assets/img/project-ico.jfif";
import projectImage from "@/assets/img/project-img-1.jpg";

import DropdownActions from "@/components/common/DropdownActions";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";

interface Material {
    material: string;
    supplier: string;
    project: string;
    stockStatus: {
        label: string;
        className: string;
    };
    quantity: string;
    lastOrdered: string;
}

interface InventoryTabProps {
    isGridView: boolean
    isTableView: boolean
}
const InventoryTab = ({ isGridView, isTableView }: InventoryTabProps) => {

    const data: Material[] = [{
        material: "Oak Hardwood Flooring",
        supplier: "ABC Building Supplies",
        project: "Modern Kitchen Remodel",
        stockStatus: {
            label: "In Stock",
            className: "pill--custom-1"
        },
        quantity: "150 sq. ft.",
        lastOrdered: "Oct 15, 2024"
    }, {
        material: "Granite Countertop",
        supplier: "XYZ Stone Works",
        project: "Luxury Bathroom Renovation",
        stockStatus: {
            label: "Out of Stock",
            className: "pill--custom-2"
        },
        quantity: "200 sq. ft.",
        lastOrdered: "Sep 25, 2024"
    }, {
        material: "Ceramic Tiles",
        supplier: "Tile World",
        project: "Kitchen Refurbishment",
        stockStatus: {
            label: "Low Stock",
            className: "pill--custom-3"
        },
        quantity: "50 sq. ft.",
        lastOrdered: "Oct 1, 2024"
    }];

    const actions = (arg: Material) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];
    const columns: ColumnDef<Material>[] = [{
        header: "Material",
        accessorKey: "material",
        minSize: 228,
        cell: ({ row: { original } }) => <p>{original.material}</p>

    },
    {
        header: "Supplier",
        accessorKey: "supplier",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.supplier}</p>

    },
    {
        header: "Project",
        accessorKey: "project",
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.project}</span>
        </a>

    },
    {
        header: "Stock Status",
        accessorKey: "stockStatus",
        cell: ({ row: { original } }) => <div className={clsx("pill-custom pill--sm", original.stockStatus.className)}>
            <span>{original.stockStatus.label}</span>
        </div>

    },
    {
        header: "Quantity",
        accessorKey: "quantity",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.quantity}</p>

    },
    {
        header: "Last Ordered",
        accessorKey: "lastOrdered",
        cell: ({ row: { original } }) => <p className="color-control-3">{original.lastOrdered}</p>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];

    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Oak Hardwood Flooring
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <BoxIcon />
                                                    </span>
                                                    <p className="project-variable--text">
                                                        ABC Building Supplies
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1 pill--sm"><span>In Stock</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <div className="card-details--projects-info--body">
                                            <ul className="card-details--projects-info-list">
                                                <li className="card-details--project-info--item">
                                                    <div className="btn btn--project-row-2">

                                                        <div className="project-titled-wrpr">
                                                            <div className="ico project-ico">
                                                                <img src={projectImageThumb} alt="project" />
                                                            </div>
                                                            <div className="event-project-status --info-4"></div>

                                                            <div className="project-info-wrapper-block">
                                                                <div className="project-info-item-block --only">
                                                                    <p className="project-title-4">Project Type</p>
                                                                    <p className="project-title-3">Design concept revision</p>
                                                                </div>
                                                                <div className="project-info-item-block --only">
                                                                    <ul className="project-list user-noname-desk">
                                                                        <li className="project-list-item">
                                                                            <div className="project-variable-wrpr">
                                                                                <p className="project-variable--text">123 Main St, Los Angeles, CA</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="project-list-item">
                                                                            <div className="project-variable-wrpr">
                                                                                <p className="project-variable--text">Quantity:</p>
                                                                            </div>
                                                                            <div className="project-value-wrpr">
                                                                                <p className="project-value--text font-500">150 sq. ft.</p>
                                                                            </div>
                                                                        </li>
                                                                        <li className="project-list-item">
                                                                            <div className="project-variable-wrpr">
                                                                                <p className="project-variable--text">Last Ordered:</p>
                                                                            </div>
                                                                            <div className="project-value-wrpr">
                                                                                <p className="project-value--text font-500">Oct 15, 2024</p>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><EditIcon /></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    return <Table columns={columns} data={data} selectable />;

};

export default InventoryTab;
