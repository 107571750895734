import React, { useEffect, useRef } from "react";
import Button from "@/components/common/Button";
import placeholder from "@/assets/img/placeholder.png";
import { getMediaUrl } from "@/utils";

interface UploadPhotoProps {
    onChange: (file: File | null | string) => void;
    value: string | File | null;
}

const UploadPhoto = ({ onChange, value }: UploadPhotoProps) => {

    const inputRef = useRef<HTMLInputElement | null>(null);
    const imgRef = useRef<HTMLImageElement | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files?.[0] || null;
        if (file) {

            const reader = new FileReader();
            reader.onload = () => {

                imgRef.current!.src = reader.result as string;
                onChange(file);

            };
            reader.readAsDataURL(file);

        }

    };

    useEffect(() => {

        if (value && typeof value === "string") {

            imgRef.current!.src = getMediaUrl(value);

        } else if (!value) {

            imgRef.current!.src = placeholder;

        }

    }, [value]);

    return (
        <div className="upload-ava-wrpr">
            <div className="ico ico-ava ico-ava-80">
                <img ref={imgRef} src={value
                    ? typeof value === "string"
                        ? value
                        : URL.createObjectURL(value)
                    : placeholder} alt="Uploaded Preview" />
            </div>
            <div className="upload-actions">
                <label className="fake-btn">
                    <input
                        ref={inputRef}
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                    <div className="btn btn--md btn--custom-1 rounded-full">
                        <span>Upload Picture</span>
                    </div>
                </label>
            </div>
        </div>
    );

};

export default UploadPhoto;
