import clsx from "clsx";
import type { Country, Value } from "react-phone-number-input";
import PhoneInputWithCountrySelect, { DefaultInputComponentProps } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Select from "./Select";

interface PhoneInputProps extends DefaultInputComponentProps {
    label?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    error?: boolean | string;
    onChange: (value: Value) => void
    onCountryChange?: (country: Country) => void
}

const PhoneInput = ({ label, placeholder, className = "input--xl", error, onChange, inputClassName = "input--outline", onCountryChange, ...props }: PhoneInputProps) => {
    return (
        <div className={clsx("form-group", className)}>
            {label && <label className="label">{label}</label>}
            <PhoneInputWithCountrySelect
                onChange={onChange}
                onCountryChange={onCountryChange}
                className="row-group gap--xs"
                numberInputProps={{
                    className: clsx("input", inputClassName, {
                        "input-error": error,
                        "input-disabled": props.disabled
                    })
                }}
                countrySelectComponent={(props) => {

                    const options = props.options.map(({ value }) => ({
                        value,
                        label: <props.iconComponent country={value} />
                    }));
                    return <Select options={options} onChange={(option) => props.onChange(option.value)} value={props.value} />;

                }}
                {...props}
            />
            {typeof error === "string" && <p className="error-message">{error}</p>}
        </div>

    );

};

export default PhoneInput;
