import React from "react";

import projectImage from "@/assets/img/project-img-1.jpg";
import DropdownActions from "@/components/common/DropdownActions";

import UserIcon from "@/assets/img/ico-user.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import Table from "@/components/common/Table";
import { ColumnDef } from "@tanstack/react-table";

interface InvoicesTabProps {
    isGridView: boolean
    isTableView: boolean
}

interface Invoice {
    invoiceNumber: string;
    projectName: string;
    clientName: string;
    issueDate: string;
    dueDate: string;
    amount: number;
    status: string;
}

const InvoicesTab = ({ isGridView, isTableView }: InvoicesTabProps) => {

    const data: Invoice[] = [{
        invoiceNumber: "INV12345",
        projectName: "Project A",
        clientName: "Client X",
        issueDate: "2024-01-15",
        dueDate: "2024-02-15",
        amount: 1500,
        status: "Pending"
    }, {
        invoiceNumber: "INV12346",
        projectName: "Project B",
        clientName: "Client Y",
        issueDate: "2024-02-10",
        dueDate: "2024-03-10",
        amount: 2000,
        status: "Paid"
    }, {
        invoiceNumber: "INV12347",
        projectName: "Project C",
        clientName: "Client Z",
        issueDate: "2024-03-05",
        dueDate: "2024-04-05",
        amount: 2500,
        status: "Overdue"
    }, {
        invoiceNumber: "INV12348",
        projectName: "Project D",
        clientName: "Client A",
        issueDate: "2024-04-20",
        dueDate: "2024-05-20",
        amount: 3000,
        status: "Pending"
    }];


    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">

                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr">
                                                    <img src={projectImage} alt="project" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                #INV-001
                                            </p>
                                        </div>

                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <UserIcon />
                                                    </span>
                                                    <p className="project-variable--text">Client Title</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Amount ($):</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">$32,000</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-statuses">
                                                    <div className="pill-custom pill--custom-1
                                                                pill--sm"><span>Paid</span></div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <ul className="project-list user-noname-desk">
                                            <li className="project-list-item">
                                                <a href="#" className="btn btn-link color-secondary tdu">
                                                    <span className="font-400 text--sm">Modern Kitchen Remodel</span>
                                                </a>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Issue Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">Oct 15, 2024</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <p className="project-variable--text">Due Date:</p>
                                                </div>
                                                <div className="project-value-wrpr">
                                                    <p className="project-value--text font-500">Oct 15, 2024</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>Change Status</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><EditIcon /></span>
                                                    <span>Edit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );

    }

    const columns: ColumnDef<Invoice>[] = [{
        header: "Invoice Number",
        accessorKey: "invoiceNumber",
        minSize: 150,
        cell: ({ row: { original } }) => <p className="color-control-3">{original.invoiceNumber}</p>

    },
    {
        header: "Project Name",
        accessorKey: "projectName",
        minSize: 150,
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.projectName}</span>
        </a>

    },
    {
        header: "Client Name",
        accessorKey: "clientName",
        minSize: 150,
        cell: ({ row: { original } }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{original.clientName}</span>
        </a>

    },
    {
        header: "Issue Date",
        accessorKey: "issueDate",
        minSize: 120,
        cell: ({ row: { original } }) => <p className="color-control-3 table-text-nowrap">{original.issueDate}</p>
    },
    {
        header: "Due Date",
        accessorKey: "dueDate",
        minSize: 120,
        cell: ({ row: { original } }) => <p className="color-status-6 table-text-nowrap">{original.dueDate}</p>
    },
    {
        header: "Amount ($)",
        accessorKey: "amount",
        minSize: 120,
        cell: ({ row: { original } }) => <p className="font-500">{original.amount}</p>
    },
    {
        header: "Status",
        accessorKey: "status",
        minSize: 120,
        cell: ({ row: { original } }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        minSize: 120,
        cell: ({ row: { original } }) => <DropdownActions variant="secondary" actions={[]} />

    }];


    return <Table columns={columns} data={data} selectable />;

};

export default InvoicesTab;
