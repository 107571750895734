import { Status } from "@/types/emums";
import * as yup from "yup";

export const teamSchema = yup.object().shape({
    name: yup.string().required(),
    icon: yup.string().required(),
    status: yup.string().oneOf(['active', 'inactive']).required(),
    TeamLeadId: yup.number().required(),
    DepartmentId: yup.number().required(),
    Members: yup.array().of(
        yup.object().shape({
            id: yup.number().required(),
            profession: yup.string().required(),
            status: yup.string().oneOf(Object.values(Status)).required(),
        })
    ).required(),
});

export type TeamFormRequest = yup.InferType<typeof teamSchema>;
