import Input from "@/components/common/Input";
import Select, { OptionCustomProps } from "@/components/common/Select";
import { cityOptions, countryOptions, stateOptions } from "@/constants/options";
import { ClientFormRequest } from "@/validation/clientSchema";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { UseFormReturn } from "react-hook-form";

interface LocationProps {
    formState: UseFormReturn<ProjectFormRequest>
}
const Location = ({ formState }: LocationProps) => {

    const { register, watch, setValue, resetField, formState: { errors, defaultValues } } = formState;

    const handleCountryChange = (option: OptionCustomProps<string>) => {

        setValue("address.country", option.value);
        setValue("address.state", null);
        setValue("address.city", null);
        resetField("address.state");
        resetField("address.city");

    };
    const handleStateChange = (option: OptionCustomProps<string>) => {

        setValue("address.state", option.value);
        setValue("address.city", null);
        resetField("address.city");

    };
    const handleCityChange = (option: OptionCustomProps<string>) => {

        setValue("address.city", option.value);

    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Country</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={countryOptions}
                            onChange={handleCountryChange}
                            value={watch("address.country")}
                            error={errors.address?.country?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">State</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={stateOptions(watch("address.country"))}
                            onChange={handleStateChange}
                            value={watch("address.state")}
                            disabled={!watch("address.country")}
                            error={errors.address?.state?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">City</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={cityOptions(watch("address.country"), watch("address.state"))}
                            onChange={handleCityChange}
                            value={watch("address.city")}
                            disabled={!watch("address.country") || watch("address.country") && stateOptions.length && !watch("address.state")}
                            error={errors.address?.city?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Street Address</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("address.street")}
                            placeholder="Enter street address..."
                            error={errors.address?.street?.message}
                            disabled={!watch("address.city")}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Building</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("address.building")}
                            placeholder="Enter building..."
                            error={errors.address?.building?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">ZIP Code</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("address.zip")}
                            placeholder="Enter zip code..."
                            error={errors.address?.zip?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Unit</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("address.unit")}
                            placeholder="Enter unit..."
                            error={errors.address?.unit?.message}
                        />
                    </div>
                </div>
            </div>


        </div>
    );

};

export default Location;
