import ChevronRightIcon from "@/assets/img/chevron-arrow-right.svg?react";
import ChevronsIcon from "@/assets/img/chevrons-left-right.svg?react";
import BudgetIcon from "@/assets/img/icon-budget.svg?react";
import CheckIcon from "@/assets/img/icon-check-10.svg?react";
import ChevronAccordionIcon from "@/assets/img/icon-chevron-accordeon.svg?react";
import ClientsIcon from "@/assets/img/icon-clients.svg?react";
import DashboardIcon from "@/assets/img/icon-dashboard.svg?react";
import DesignsIcon from "@/assets/img/icon-designs.svg?react";
import PlusNewIcon from "@/assets/img/icon-plus-new.svg?react";
import ProjectsIcon from "@/assets/img/icon-projects.svg?react";
import ReportsIcon from "@/assets/img/icon-reports.svg?react";
import SchedulingIcon from "@/assets/img/icon-scheduling.svg?react";
import SettingsIcon from "@/assets/img/icon-settings.svg?react";
import SuppliersIcon from "@/assets/img/icon-suppliers.svg?react";
import TeamManagementIcon from "@/assets/img/icon-team-management.svg?react";
import LogoContainerClosedIcon from "@/assets/img/logo-container-closed.svg?react";
import LogoContainerOpenIcon from "@/assets/img/logo-container-open.svg?react";
import StreamlineIcon from "@/assets/img/panel-streamline-lucide.svg?react";
import SelectionDragIcon from "@/assets/img/selection-drag.svg?react";
import UsersIcon from "@/assets/img/users-icon.svg?react";


import projectImage1 from "@/assets/img/img-project-name-1.jpg";
import projectImage2 from "@/assets/img/img-project-name-2.jpg";
import projectImage3 from "@/assets/img/img-project-name-3.jpg";
import projectImage4 from "@/assets/img/img-project-name-4.jpg";
import projectImage5 from "@/assets/img/img-project-name-5.jpg";
import { routerConfig } from "@/routes";
import { Link, NavLink } from "react-router";
import clsx from "clsx";
import Accordion from "../common/Accordion";
import Dropdown from "../common/Dropdown";
import { useEffect, useRef, useState } from "react";
import { useGetAllProjectsQuery } from "@/services/projectsApi";
import Loader from "../common/Loader";
import Image from "../common/Image";
import { getFullUrl } from "@/utils";


const iconMap = {
    dashboard: <DashboardIcon />,
    projects: <ProjectsIcon />,
    clients: <ClientsIcon />,
    designs: <DesignsIcon />,
    scheduling: <SchedulingIcon />,
    suppliers: <SuppliersIcon />,
    budget: <BudgetIcon />,
    team: <TeamManagementIcon />,
    reports: <ReportsIcon />,
    users: <UsersIcon />,
    settings: <SettingsIcon />
};


const Sidebar = () => {

    const projectImages = [projectImage1,
        projectImage2,
        projectImage3,
        projectImage4,
        projectImage5];

    const projects = projectImages.map((photo, index) => ({
        name: `Project name ${index + 1}`,
        photo
    }));

    const handleToggleSidebar = () => {

        if (document.body.classList.contains("sidebar-small")) {

            document.body.classList.remove("sidebar-small");

        } else {

            document.body.classList.add("sidebar-small");

        }

    };

    const routes = Object.entries(routerConfig).filter(([key, value]) => key !== "login");
    return (
        <aside className="sidebar">

            <div className="sidebar__container">
                <div className="sidebar__head">
                    <div className="sidebar__logo">
                        <div className="sidebar__logo-open">
                            <LogoContainerOpenIcon />
                        </div>
                        <div className="sidebar__logo-closed">
                            <LogoContainerClosedIcon />
                        </div>
                    </div>
                    <button onClick={handleToggleSidebar} className="sidebar__head-btn">
                        <StreamlineIcon />
                    </button>

                </div>
                <div className="sidebar__body">
                    <DepartmentList />
                    <Accordion
                        wrapperClassName="sidebar__accordeon"
                        bodyClassName="sidebar__accordeon-body"
                        defaultOpen
                        button={(
                            <button className="sidebar__accordeon-btn flex-auto">
                                <span>Workspace</span>
                                <div className="chevron-accordeon">
                                    <ChevronAccordionIcon />
                                </div>
                            </button>
                        )}
                    >
                        <ul className="sidebar-menu">
                            {routes.map(([key, { path, name }]) => <li key={key} className="sidebar-menu--item">
                                <NavLink
                                    to={path}
                                    className={({ isActive }) => clsx("btn size--md sidebar-link", { "active-link": isActive })}
                                >
                                    <span className="ico">
                                        {iconMap[key as keyof typeof iconMap]}
                                    </span>
                                    <p className="sidebar-link--text">{name}</p>
                                </NavLink>
                            </li>)}
                        </ul>
                    </Accordion>

                    <Accordion
                        wrapperClassName="sidebar__accordeon"
                        bodyClassName="sidebar__accordeon-body"
                        defaultOpen
                        button={(
                            <button className="sidebar__accordeon-btn flex-auto">
                                <span>Projects</span>
                                <div className="chevron-accordeon">
                                    <ChevronAccordionIcon />
                                </div>
                            </button>
                        )}
                    >
                        <ProjectList />
                    </Accordion>

                </div>
            </div>
        </aside>
    );

};


function ProjectList() {
    const { data: response, isLoading } = useGetAllProjectsQuery({
        limit: 5
    })
    const data = response?.data

    if (isLoading) {
        return (
            <div className="flex items-center justify-center">
                <Loader size={24} />
            </div>
        )
    }
    return (
        <ul className="sidebar-menu">
            {
                data.map((item, index) => <li key={index} className="sidebar-menu--item">
                    <a href="#" className="btn size--md sidebar-link">
                        <span className="sidebar__project-img">
                            <Image src={item.cover} alt={item.name} />
                        </span>
                        <p className="sidebar-link--text">{item.name}</p>
                    </a>
                </li>)
            }
            <li className="sidebar-menu--item">
                <Link to={getFullUrl([routerConfig.projects.path])} className="all-projects">
                    <span className="all-projects__text">Show all projects</span>
                    <div className="arrow-box">
                        <ChevronRightIcon />
                    </div>
                </Link>
            </li>
        </ul>
    )
}

function DepartmentList() {

    const [selectedDepartment, setSelectedDepartment] = useState<string>("All Departments");
    const dropdownRef = useRef<any>(null);
    const handleSelectDepartment = (department: string) => {

        setSelectedDepartment(department);
        if (dropdownRef.current?.close) {

            dropdownRef.current.close();

        }

    };
    return (
        <Dropdown
            dropdownRef={dropdownRef}
            dropdownClasses="dropdown-sidebar"
            bodyClassName="dropdown__body dropdown-sidebar__body dropdown__table"
            button={
                <button type="button" className="dropdown-sidebar-btn dropdown-btn w-full">
                    <div className="dropdown-sidebar__group-selection">
                        <div className="dropdown-sidebar__box" style={{ backgroundColor: "#177E89" }}>5</div>
                        <span className="dropdown-sidebar__selection">{selectedDepartment}</span>
                    </div>
                    <div className="dropdown-sidebar__arrows">
                        <ChevronsIcon />
                    </div>
                </button>
            }
        >
            <div className="dropdown-block">
                <div className="dropdown-block--body">
                    <ul className="dropdown-sidebar__selection-list">
                        {["All Departments",
                            "California Dept.",
                            "New York Dept.",
                            "Florida Dept.",
                            "Massachusetts Dept.",
                            "Washington Dept."].map((dept, index) => <li
                                key={index}
                                className={clsx("dropdown-sidebar__selection-item", { "checked-selection": dept === selectedDepartment })}
                                onClick={() => handleSelectDepartment(dept)}
                            >
                                <a href="#" className="selection-drag">
                                    <SelectionDragIcon />
                                </a>
                                <div
                                    className="dropdown-sidebar__box"
                                    style={{
                                        backgroundColor: dept === "All Departments"
                                            ? "#177E89"
                                            : dept === "California Dept."
                                                ? "#E9830F"
                                                : dept === "New York Dept."
                                                    ? "#1588E0"
                                                    : dept === "Florida Dept."
                                                        ? "#49A514"
                                                        : dept === "Massachusetts Dept."
                                                            ? "#49A514"
                                                            : "#6613CB"
                                    }}
                                >
                                    {dept.charAt(0)}
                                </div>
                                <span className="dropdown-sidebar__selection">{dept}</span>
                                {dept === selectedDepartment &&
                                    <div className="dropdown-sidebar__selection-checked">
                                        <CheckIcon />
                                    </div>
                                }
                            </li>)}
                    </ul>
                    <a href="#" className="dropdown-sidebar__new-department">
                        <div className="icon-18x18">
                            <PlusNewIcon />
                        </div>
                        New Department
                    </a>
                </div>
            </div>
        </Dropdown>
    );

}

export default Sidebar;
