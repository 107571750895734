import AssistantButton from "@/components/common/AssistantButton";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import Select from "@/components/common/Select";

import ImportIcon from "@/assets/img/ico-import.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import OrderIcon from "@/assets/img/ico-order.svg?react";
import CalendarIcon from "@/assets/img/ico-calendar-2.svg?react";
import FlagIcon from "@/assets/img/ico-flag.svg?react";
import userImage from "@/assets/img/img-user-1.png";
import { routerConfig } from "@/routes";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";

interface PlanningCols {
    [key: string]: number;
}

const DesignsProposalsPage = () => {

    useBreadcrumbs([routerConfig.designs]);
    const projectTypeOptions = [{ label: "Project Type",
        value: "" }];

    const planningCols: PlanningCols = {
        "Design Retainer Paid": 7,
        "Space Planning Review": 2,
        "Finishes Planning Review": 0,
        "Contract Signed": 0,
        "Deposit Paid": 10,
        "Closed Lost": 15
    };

    const totalCols = Object.keys(planningCols).length;
    const colWidth = `${100 / totalCols}%`;
    return (
        <div className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <div className="flex-controls gap--xs">
                                    <Search />
                                    <Filters />
                                </div>
                                <div className="flex-controls gap--xs">
                                    <Select options={projectTypeOptions} placeholder="Project Type" wrapperClassName="w-200" />
                                </div>
                            </div>
                            <div className="heading-option">
                                <div className="flex-controls gap--xs">
                                    <AssistantButton text="Ask Your CFO" />
                                    <button className="btn btn--custom-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><ImportIcon /></span>
                                        <span>Import</span>
                                    </button>
                                    <button className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><AddIcon /></span>
                                        <span>New Project</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className="view-page-block tabs-fullpage active-page">
                            <div className="tab active-tab">

                                <div className="widget-planing-wrpr">
                                    <div className="widget-planing--list">
                                        {Object.entries(planningCols).map(([key, count]) => <div
                                            key={key}
                                            className="planing-item"
                                            style={{
                                                width: colWidth,
                                                maxWidth: colWidth,
                                                minWidth: "170px"
                                            }}
                                        >
                                            <div className="planing-header --bordered">
                                                <div className="planing-header-titled">
                                                    <p className="planing-title text--sm">{key}</p>
                                                </div>
                                                <div className="planing-header-actions">
                                                    <p className="planing-counter-text">{count}</p>
                                                </div>
                                            </div>
                                            <div className="planing-body">
                                                <ul className="planing--cards-list">
                                                    {Array.from({ length: count }, (_, index) => <li key={index} className="planing-card-item">
                                                        <PlanningCard />
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

};


function PlanningCard () {

    return (
        <div className="card-widget card-widget-task">
            <div className="card-widget-body">
                <div className="card-details-wrpr">
                    <div className="card-details--item">
                        <div className="card-details-planing-wrpr">
                            <div className="card-details-heading">
                                <p className="user-block--role text--sm">Project Type</p>
                                <p className="heading user-block-title text--md">Eleanor Pena</p>
                            </div>
                            <ul className="project-list">
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <OrderIcon />
                                        </span>
                                        <p className="project-value--text font-500">$100,000</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <CalendarIcon />
                                        </span>
                                        <p className="project-variable--text">01.02.2024</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <FlagIcon />
                                        </span>
                                        <p className="project-variable--text">23.10.2024</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="card-details--head card-details--users-row">
                                <div className="user-list-wrpr">
                                    <ul className="user-list">
                                        <li className="user-item"><img src={userImage} alt="user" /></li>
                                    </ul>
                                </div>
                                <div className="pill-custom-text --warning">
                                    <span className="pill-text">Medium</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default DesignsProposalsPage;
