import { Chart, Plugin } from "chart.js";
import { AnyObject } from "chart.js/dist/types/basic";

interface RoundedArc {
    x: number;
    y: number;
    radius: number;
    thickness: number;
    backgroundColor: string;
}

export const DoughnutRoundedCornersPlugin: Plugin<"doughnut"> = {
    id: "rounded-doughnut-corners",

    afterUpdate: function (chart) {

        const arcElement = chart.getDatasetMeta(0).data[0];

        if (!arcElement) return;

        const arc: RoundedArc = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: (arcElement.outerRadius + arcElement.innerRadius) / 2,
            thickness: (arcElement.outerRadius - arcElement.innerRadius) / 2,
            backgroundColor: arcElement.options.backgroundColor
        };

        arcElement.round = arc;

    },

    afterDraw: function (chart) {

        const { ctx } = chart;
        const legendItems = chart.options.plugins?.legend?.labels?.generateLabels(chart) ?? [];
        const datasetItems = chart.getDatasetMeta(0).data;
        const lastItemIndex = datasetItems.length - 1;
        datasetItems.forEach((item, idx) => {

            if (idx < lastItemIndex && !legendItems[idx].hidden) {

                const arc = item;
                const firstArc = datasetItems[0];
                const endAngle = Math.PI / 2 - arc.endAngle;
                const startAngle = Math.PI / 2 - arc.startAngle;

                ctx.save();
                ctx.translate(firstArc.round.x, firstArc.round.y);
                ctx.fillStyle = arc.options.backgroundColor;

                ctx.beginPath();
                ctx.arc(firstArc.round.radius * Math.sin(startAngle), firstArc.round.radius * Math.cos(startAngle), firstArc.round.thickness, 0, 2 * Math.PI);
                ctx.arc(firstArc.round.radius * Math.sin(endAngle), firstArc.round.radius * Math.cos(endAngle), firstArc.round.thickness, 0, 2 * Math.PI);
                ctx.closePath();
                ctx.fill();
                ctx.restore();

            }

        });

    }
};

interface HtmlLegendPluginOptions {
    containerID: string;
}

const getOrCreateLegendList = (
    chart: Chart<"doughnut", number[], unknown>,
    id: string
): HTMLUListElement | undefined => {

    const legendContainer = document.getElementById(id);
    if (!legendContainer) return;

    let listContainer = legendContainer.querySelector("ul");
    if (!listContainer) {

        listContainer = document.createElement("ul");
        listContainer.className = "legendList";
        legendContainer.appendChild(listContainer);

    }

    return listContainer;

};

export const htmlLegendPlugin = (
    total: number = 0,
    format?: (n: number) => string
): Plugin<"doughnut", AnyObject> => ({
    id: "htmlLegend",
    afterUpdate (chart, args, options: HtmlLegendPluginOptions) {

        const { ctx } = chart;
        ctx.save();

        const ul = getOrCreateLegendList(chart, options.containerID);
        if (!ul) return;

        while (ul.firstChild) {

            ul.firstChild.remove();

        }

        const labels = chart.options.plugins?.legend?.labels;
        if (!labels || !Object.hasOwn(labels, "generateLabels")) return;

        const items = labels.generateLabels(chart);
        if (!items?.length) return;

        items.forEach((item) => {

            const value = chart.getDatasetMeta(0)._parsed[item.index];
            const parsedValue = format
                ? format(value)
                : value;

            const li = document.createElement("li");
            li.className = "legend-item";

            const boxSpan = document.createElement("span");
            boxSpan.className = "boxSpan";
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;

            const textContainer = document.createElement("div");
            textContainer.className = "legend-wrapper-btn";
            textContainer.style.color = item.fontColor;

            li.addEventListener("click", () => {

                const { type } = chart.config;
                if (type === "pie" || type === "doughnut") {

                    chart.toggleDataVisibility(item.index);
                    total += item.hidden
                        ? -value
                        : value;
                    chart.update();

                } else {

                    total += item.hidden
                        ? -value
                        : value;

                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    chart.update();

                }

            });

            textContainer.style.textDecoration = item.hidden
                ? "line-through"
                : "";

            textContainer.innerHTML = `
                <div class="legend-box">
                    <div class="legend-box-bg-status" 
                    style="background-color:${item.fillStyle};"></div>
                    <div class="legend-box-content">
                        <p class="legend-box-variable">${item.text}</p>
                        <p class="legend-box-value">${parsedValue}</p>
                    </div>
                </div>
            `;

            li.appendChild(textContainer);
            ul.appendChild(li);

        });

    }
});


interface DoughnutTotalPluginProps {
    value?: number;
    label?: string;
    format?: (n: number) => string;
}

export const DoughnutTotalPlugin = ({
    value,
    label,
    format = (n) => n.toString()
}: DoughnutTotalPluginProps): Plugin<"doughnut", AnyObject> => ({
    id: "DoughnutTotalPlugin",
    afterDatasetDraw (chart: Chart<"doughnut">) {

        const { ctx } = chart;
        const datasetMeta = chart.getDatasetMeta(0);
        const firstDataPoint = datasetMeta.data[0];

        if (!firstDataPoint) return;


        const values = chart.data.datasets[0]?.data.filter((_, index) => chart.getDataVisibility(index));

        const calculatedValue =
            value !== undefined
                ? value
                : values.reduce((sum, num) => sum + (typeof num === "number"
                    ? num
                    : 0), 0);

        ctx.save();
        ctx.textAlign = "center";
        ctx.fillStyle = "#161616";
        ctx.font = "500 14px Onest";

        ctx.fillText(`${format(calculatedValue)}`, firstDataPoint.x, label !== undefined
            ? firstDataPoint.y - 10
            : firstDataPoint.y);

        if (label !== undefined) {

            ctx.textAlign = "center";
            ctx.fillStyle = "#979797";
            ctx.font = "400 12px Onest";
            ctx.fillText(`${label}`, firstDataPoint.x, firstDataPoint.y + 10);

        }

        ctx.restore();

    }
});

