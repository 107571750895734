import { ColumnDef } from '@tanstack/react-table';
import DropdownActions, { DropdownActionsProps } from './DropdownActions';
import DocIconColored from "@/assets/img/ico-doc-colored.svg?react";
import Table from './Table';
import { Document } from "@/types";


type Actions = DropdownActionsProps['actions']
interface DocumentsTableProps {
    data: Document[]
    actions: (arg: Document) => Actions
}

function DocumentsTable({ data, actions }: DocumentsTableProps) {

    const columns: ColumnDef<Document>[] = [{
        header: "User Name",
        accessorKey: "firstName",
        minSize: 228,
        cell: ({ row: { original } }) => 
            <div className="table-user-info-texts">
                <div className='flex items-center'>
                    <DocIconColored className='me-3'/> 
                    <span className="text--md font-400">{original.name}</span>
                </div>
            </div>
    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        enablePinning: true,
        cell: ({ row: { original } }) => <DropdownActions
            variant="secondary"
            actions={actions(original)}
        />

    }];


    return (
        <div className="table-accord">
            <Table columns={columns} data={data} hideFooter />
        </div>
        )
}

export default DocumentsTable;