import React from "react";
import Button from "@/components/common/Button";
import ChevronRightIcon from "@/assets/img/chevron-right-icon.svg?react";
import ChevronLeftIcon from "@/assets/img/chevron-left-icon.svg?react";


interface EntityFooterProps {
    isLoading?: boolean;
    handleCancel: () => void;
    handleGoBack: () => void;
    onSubmit?: () => void;
    completeText?: string;
    nextStepText?: string;
    isFinalStep?: boolean
    isFirstStep?: boolean
}

const EntityFooter: React.FC<EntityFooterProps> = ({
    isLoading = false,
    handleCancel,
    handleGoBack,
    onSubmit,
    completeText = "Complete",
    nextStepText = "Next Step",
    isFinalStep,
    isFirstStep
}) => {
    return (
        <div className="modal__footer justify-between">
            <Button
                loading={isLoading}
                onClick={isFirstStep ? handleCancel : handleGoBack}
                className="btn btn--md btn--custom-1 rounded-full"
            >
                {isFirstStep
                    ? "Cancel"
                    : <div className="flex items-center"><span className="ico"><ChevronLeftIcon /></span>Back</div>
                }
            </Button>
            <Button
                loading={isLoading}
                type="submit"
                onClick={onSubmit}
                className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class"
            >
                <span>{isFinalStep ? completeText : nextStepText}</span>
                {!isFinalStep && (
                    <span className="ico">
                        <ChevronRightIcon />
                    </span>
                )}
            </Button>
        </div>
    );
};

export default EntityFooter;