import React from "react";
import Loader from "../common/Loader";

const PageLoader = () => {

    return (
        <div className="content ovh" style={{ backgroundColor: "var(--white)" }}>
            <div className="container container-full px-0 h-full flex flex-col items-center justify-center">
                <Loader size={100} />
            </div>
        </div>
    );

};

export default PageLoader;
