import { ModalProps } from "@/components/modals/Modal";
import TeamData from "./components/TeamData";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { Team } from "@/types";

interface NewTeamModal extends ModalProps {
    data: Team
}

const completeActionButtons = [{
    text: "Add Project",
    handler: () => { }
}, {
    text: "Add Task",
    handler: () => { }
}];

const NewTeamModal = ({ data, ...props }: NewTeamModal) => {

    const { currentStage, handleCompleteClick, handleContinueClick, handleCancel } = useNewEntityModal();

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Team Created!"
            completeDescription="Congratulations on successfully creating a new team! You can now start managing members, projects or assign tasks."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
        >
            <TeamData data={data} handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );

};

export default NewTeamModal;
