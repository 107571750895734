import { EntityCreationStages } from "@/types/emums";
import { useCallback, useState } from "react";
import useModal from "./useModal";
import { VoidFunctionWithoutArguments } from "@/types";

type UseNewEntityModal = () => {
    currentStage: EntityCreationStages,
    handleContinueClick: (isCsvLoading?: boolean) => void;
    handleCompleteClick: VoidFunctionWithoutArguments;
    handleCancel: VoidFunctionWithoutArguments;
    handleGoToSelection: VoidFunctionWithoutArguments;
}

export const useNewEntityModal: UseNewEntityModal = () => {

    const { closeModal } = useModal();
    const [currentStage, setCurrentStage] = useState<EntityCreationStages>(EntityCreationStages.SelectOption);

    const handleContinueClick = useCallback((isCsvLoading: boolean = false) => {
        let stage = EntityCreationStages.FillData;

        if (isCsvLoading) {
            stage = EntityCreationStages.UploadCsv
        }

        setCurrentStage(stage);

    }, []);

    const handleCompleteClick = useCallback(() => {

        setCurrentStage(EntityCreationStages.Complete);

    }, []);

    const handleCancel = () => {

        closeModal();

    };

    const handleGoToSelection = useCallback(() => {

        setCurrentStage(EntityCreationStages.SelectOption);

    }, []);

    return {
        currentStage,
        handleContinueClick,
        handleCompleteClick,
        handleCancel,
        handleGoToSelection,
    };

};
