import FireIcon from "@/assets/img/ico-fire.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import LocationIcon from "@/assets/img/ico-location.svg?react";
import EmailIcon from "@/assets/img/ico-email.svg?react";
import PhoneIcon from "@/assets/img/ico-phone.svg?react";
import ProfileIcon from "@/assets/img/ico-profile.svg?react";
import { Client } from "@/types";
import DropdownActions, { DropdownActionsProps } from "@/components/common/DropdownActions";
import { Link } from "react-router";
import { getFullUrl } from "@/utils";
import { routerConfig } from "@/routes";
import Image from "@/components/common/Image";

type Actions = DropdownActionsProps['actions']
interface ProjectCardProps {
    data: Client
    actions: (arg: Client) => Actions
}

const ClientCard = ({ data, actions }: ProjectCardProps) => {

    const lastProject = null
    return (
        <div className="card-widget card-widget-product w-full h-full">
            <div className="card-widget-body">
                <div className="card-details-wrpr">
                    <div className="card-details--item">
                        <div className="card-details--head">
                            <div className="user-option-block user-option-block--sm">
                                <div className="user-block-relatives">
                                    <div className="ico ico--lg user-block-img-wrpr rounded-full">
                                        <Image src={data.photo} alt={data.fullName} />
                                    </div>
                                    {/* TODO */}
                                    {true && <div className="ico user-ico-hot-wrpr">
                                        <span className="ico user-ico-hot --active">
                                            <FireIcon />
                                        </span>
                                    </div>}
                                </div>
                            </div>
                            <div className="card-details-actions">
                                {data.isNew && <div className="pill-custom pill--custom-11 pill--xs">
                                    <span>New Client</span>
                                </div>}
                                <DropdownActions
                                    button={(
                                        <button type="button" className="btn btn--custom-1-trsp rounded-full max-w-max dropdown-btn"
                                            data-role="button">
                                            <span className="ico"><DotsIcon /></span>
                                        </button>
                                    )}
                                    actions={actions(data)}
                                />
                            </div>
                        </div>
                        <div className="card-details--body">
                            <div className="card-details-heading">
                                <Link to={getFullUrl([routerConfig.clients.path, data.id])} className="heading user-block-title text--xl">
                                    {data.fullName}
                                </Link>
                            </div>
                            <ul className="project-list user-noname-desk contacts-list">
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <LocationIcon />
                                        </span>
                                        <p className="project-variable--text">{data.address.country}</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <EmailIcon />
                                        </span>
                                        <p className="project-variable--text">{data.email}</p>
                                    </div>
                                </li>
                                <li className="project-list-item">
                                    <div className="project-variable-wrpr">
                                        <span className="ico project-variable--ico">
                                            <PhoneIcon />
                                        </span>
                                        <p className="project-variable--text">{data.phone}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-details--item">
                        <div className="card-details--body">

                            <div className="card-details--projects-info-wrpr">
                                <div className="card-details--head card-details--project-row">
                                    <ul className="counter-projects-list">
                                        <li className="counter-project-item">
                                            <div className="counter-project-text">
                                                <div className="pill-custom pill--xs">{"TODO"}</div>
                                                <p className="counter-project-value">Active Projects</p>
                                            </div>
                                        </li>
                                        <li className="counter-project-item">
                                            <div className="counter-project-text">
                                                <div className="pill-custom pill--xs">{"TODO"}</div>
                                                <p className="counter-project-value">Completed</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-details--projects-info--body">
                                    {
                                        lastProject &&
                                        <ul className="card-details--projects-info-list">
                                            <li className="card-details--project-info--item">
                                                <div className="btn btn--project-row-2">

                                                    <div className="project-titled-wrpr">
                                                        <div className="ico project-ico">
                                                            <img src={lastProject?.photo} />
                                                        </div>
                                                        <div className="event-project-status --info-4"></div>

                                                        <div className="project-info-wrapper-block">
                                                            <div className="project-info-item-block --only">
                                                                <p className="project-title-4">{lastProject?.type}</p>
                                                                <p className="project-title-3">{lastProject?.projectName}</p>
                                                            </div>
                                                            <div className="project-info-item-block --only">
                                                                <ul className="project-list user-noname-desk">
                                                                    <li className="project-list-item">
                                                                        <div className="project-variable-wrpr">
                                                                            <p className="project-variable--text">{lastProject?.address}</p>
                                                                        </div>
                                                                    </li>
                                                                    <li className="project-list-item">
                                                                        <div className="project-variable-wrpr">
                                                                            <p className="project-variable--text">Est. Project Volume:</p>
                                                                        </div>
                                                                        <div className="project-value-wrpr">
                                                                            <p className="project-value--text font-500">${lastProject?.estVolume}</p>
                                                                        </div>
                                                                    </li>
                                                                    <li className="project-list-item">
                                                                        <div className="project-variable-wrpr">
                                                                            <p className="project-variable--text">Design Retainer:</p>
                                                                        </div>
                                                                        <div className="project-value-wrpr">
                                                                            <p className="project-value--text font-500">${lastProject?.desRetainer}</p>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                            <div className="card-details--actions">
                                <div className="card-details--action-item">
                                    <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                        <span>View Projects</span>
                                    </a>
                                </div>
                                <div className="card-details--action-item">
                                    <Link to={getFullUrl([routerConfig.clients.path, data.id])} className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                        <span className="ico">
                                            <ProfileIcon />
                                        </span>
                                        <span>Profile</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ClientCard;
