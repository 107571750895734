

import ChevronDownIcon from "@/assets/img/Chevron-Down--Streamline-Lucide.svg?react";
import AddSecondaryIcon from "@/assets/img/ico-add-2.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import NextIcon from "@/assets/img/ico-next.svg?react";
import PrevIcon from "@/assets/img/ico-prev.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import DoughnutChart from "@/components/charts/DoughnutChart";
import WidgetCard from "@/components/cards/WidgetCard";


const Materials = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row g-20">
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 flex flex-col">
                    <div className="row g-20">
                        <div className="col-12">
                            <MaterialsWidget />
                        </div>
                        <div className="col-12">
                            <LowStockWidget />
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-12 flex flex-col ">
                    <div className="card-widget card-widget--md  w-full h-full card-widget-scroller card-widget-sticky">
                        <div className="card-widget-header">
                            <div className="card-widget-header-item">
                                <h5 className="heading card-widget-title btn btn--xs">Materials Details</h5>
                                <div className="tabs-nav-wrapper">
                                    <label className="fake-btn">
                                        <input type="radio" name="tab-table-2" id="stock-tracking" checked hidden />
                                        <div className="tab-link-2 btn btn--xs">
                                            <span className="text--sm">Stock Tracking</span>
                                        </div>
                                    </label>
                                    <label className="fake-btn">
                                        <input type="radio" name="tab-table-2" id="suppliers" hidden />
                                        <div className="tab-link-2 btn btn--xs">
                                            <span className="text--sm">Suppliers</span>
                                        </div>
                                    </label>
                                    <label className="fake-btn">
                                        <input type="radio" name="tab-table-2" id="invoices" hidden />
                                        <div className="tab-link-2 btn btn--xs">
                                            <span className="text--sm">Invoices</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="card-widget-header-item">
                                <div className="card-widget-actions">
                                    <button type="button" className="btn btn--secondary-advance-1 btn--xs rounded-full">
                                        <span className="ico"><StarsIcon /></span>
                                        <span>Ask Your CFO</span>
                                    </button>
                                    <button type="button" className="btn btn--primary-advance-1 btn--square btn--xs rounded-full">
                                        <span className="ico"><AddSecondaryIcon /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-widget-body">
                            <div className="table-custom">
                                <div className="table-custom-body">
                                    <table className="table" cellPadding="0" cellSpacing="0">
                                        <thead className="table-sticky-head">
                                            <tr>
                                                <th className="--sort">
                                                    <p className="th-text">Material Name</p>
                                                </th>
                                                <th className="--sort" style={{ width: 140 }} >
                                                    <p className="th-text">Supplier</p>
                                                </th>
                                                <th className="--sort" style={{ width: 140 }} >
                                                    <p className="th-text"># Required</p>
                                                </th>
                                                <th className="--sort" style={{ width: 140 }} >
                                                    <p className="th-text"># Delivered</p>
                                                </th>
                                                <th className="--sort" style={{ width: 140 }} >
                                                    <p className="th-text">Status</p>
                                                </th>
                                                <th className="--dop-class-last-sticky"
                                                    style={{ width: 0 }}>
                                                    <p className="th-text">Actions</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">Oak Hardwood Flooring</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">ColorWorld</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <p className="color-control-3">150 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <p className="font-500 nowrap">75 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <div className="pill-custom pill--custom-7 pill--sm">
                                                        <span>Pending</span>
                                                    </div>
                                                </td>
                                                <td className="--dop-class-last-sticky">
                                                    <div className="dropdown" data-position="bottom-end"
                                                        data-dropdown="dropdown">
                                                        <button type="button" className="btn btn--xs btn--custom-1 rounded-full max-w-max dropdown-btn" data-role="button">
                                                            <span>Actions</span>
                                                            <span className="ico">
                                                                <ChevronDownIcon />
                                                            </span>
                                                        </button>
                                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                                            <div className="dropdown-block">
                                                                <div className="dropdown-block--body">
                                                                    <ul className="dropdown-list">
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                                <hr className="drp-list-separator" />
                                                                            </div>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">Oak Hardwood Flooring</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">ColorWorld</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <p className="color-control-3">150 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <p className="font-500 nowrap">75 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <div className="pill-custom pill--custom-6 pill--sm">
                                                        <span>Out of Stock</span>
                                                    </div>
                                                </td>
                                                <td className="--dop-class-last-sticky">
                                                    <div className="dropdown" data-position="bottom-end"
                                                        data-dropdown="dropdown">
                                                        <button type="button" className="btn btn--xs btn--custom-1 rounded-full max-w-max dropdown-btn" data-role="button">
                                                            <span>Actions</span>
                                                            <span className="ico">
                                                                <ChevronDownIcon />
                                                            </span>
                                                        </button>
                                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                                            <div className="dropdown-block">
                                                                <div className="dropdown-block--body">
                                                                    <ul className="dropdown-list">
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                                <hr className="drp-list-separator" />
                                                                            </div>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">Oak Hardwood Flooring</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="#" className="btn btn-link color-secondary tdu">
                                                        <span className="text--md font-400">ColorWorld</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <p className="color-control-3">150 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <p className="font-500 nowrap">75 sq. ft.</p>
                                                </td>
                                                <td>
                                                    <div className="pill-custom pill--custom-2 pill--sm">
                                                        <span>In Stock</span>
                                                    </div>
                                                </td>
                                                <td className="--dop-class-last-sticky">
                                                    <div className="dropdown" data-position="bottom-end"
                                                        data-dropdown="dropdown">
                                                        <button type="button" className="btn btn--xs btn--custom-1 rounded-full max-w-max dropdown-btn" data-role="button">
                                                            <span>Actions</span>
                                                            <span className="ico">
                                                                <ChevronDownIcon />
                                                            </span>
                                                        </button>
                                                        <div className="dropdown__body dropdown__table" data-role="dropdown">
                                                            <div className="dropdown-block">
                                                                <div className="dropdown-block--body">
                                                                    <ul className="dropdown-list">
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <div className="btn btn--xs drp-list-wrapp-separator">
                                                                                <hr className="drp-list-separator" />
                                                                            </div>
                                                                        </li>
                                                                        <li className="dropdown-list--item">
                                                                            <a href="#" className="btn btn--xs btn-drp-link">
                                                                                <span className="ico"><EditIcon /></span>
                                                                                <span className="info">Edit Info</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-custom-footer">
                                    <div className="table-text-footer justify-start">
                                        <div className="btn btn--xs p-0 border-none">
                                            <p className="text--sm font-400">Showing</p>
                                        </div>
                                        <div className="form-group select--outline input--xs">
                                            <select className="select-default"
                                                data-minimum-results-for-search="Infinity">
                                                <option>15</option>
                                                <option>25</option>
                                                <option>50</option>
                                            </select>
                                        </div>
                                        <div className="btn btn--xs p-0 border-none">
                                            <p className="text--sm font-400">of <span>54</span> materials</p>
                                        </div>
                                    </div>
                                    <div className="pagination-wrpr">
                                        <a href="#prev" className="btn btn--xs btn--square
            btn--custom-1 rounded-full --disabled-pagination-btn">
                                            <span className="ico"><PrevIcon /></span>
                                        </a>
                                        <ul className="pagination-list">
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination --active-page">1</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">2</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">3</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">...</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">7</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">8</a>
                                            </li>
                                            <li className="pagination-item">
                                                <a href="#" className="btn btn--square btn-pagination">9</a>
                                            </li>
                                        </ul>
                                        <a href="#next" className="btn btn--xs btn--square
            btn--custom-1 rounded-full">
                                            <span className="ico"><NextIcon /></span>
                                        </a>
                                    </div>
                                    <div className="table-text-footer justify-end">
                                        <div className="btn btn--xs p-0 border-none">
                                            <p className="text--sm font-400">Go to page</p>
                                        </div>
                                        <div className="form-group input--xs">
                                            <input className="input input--outline
                input-count-page" type="number" value="1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );

};


function MaterialsWidget () {

    const data = {
        labels: ["In Stock", "Out of Stock", "Pending Delivery", "Ordered"],
        data: [28, 12, 10, 4],
        format: (val: number) => `${val} items`
    };

    const total = {
        label: "All Materials",
        value: 54
    };

    return (
        <WidgetCard title="Materials Overview">
            <DoughnutChart data={data} total={total} variant="secondary" />
        </WidgetCard>
    );

}

function LowStockWidget () {

    return (
        <WidgetCard title="Low Stock" showMore>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "100%" }}>
                                    <div className="table-user-info-texts">
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span className="text--md font-400">Material Name 1</span>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="material-items">
                                        5/30 items
                                    </div>
                                </td>
                                <td className="--dop-class-last-sticky">
                                    <div className="actions-table">
                                        <div className="dropdown" data-position="bottom-end"
                                            data-dropdown="dropdown">
                                            <button type="button" className="btn btn--square btn--xs
                                            btn--custom-1-trsp rounded-full max-w-max dropdown-btn
                                            dropdown-btn--burger"
                                            data-role="button">
                                                <span className="ico"><DotsIcon /></span>
                                            </button>
                                            <div className="dropdown__body dropdown__table" data-role="dropdown">
                                                <div className="dropdown-block">
                                                    <div className="dropdown-block--body">
                                                        <ul className="dropdown-list">
                                                            <li className="dropdown-list--item">
                                                                <a href="#" className="btn btn--xs btn-drp-link">
                                                                    <span className="ico"><EditIcon /></span>
                                                                    <span className="info">Edit Info</span>
                                                                </a>
                                                            </li>
                                                            <li className="dropdown-list--item">
                                                                <div className="btn btn--xs drp-list-wrapp-separator">
                                                                    <hr className="drp-list-separator" />
                                                                </div>
                                                            </li>
                                                            <li className="dropdown-list--item">
                                                                <a href="#" className="btn btn--xs btn-drp-link">
                                                                    <span className="ico"><EditIcon /></span>
                                                                    <span className="info">Edit Info</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}

export default Materials;
