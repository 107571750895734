import React from "react";

const GlobalSearch = () => {

    return (
        <div className="form-group input--md flex-auto hidden-768">
            <input className="input input--solid max-w-300" type="text" placeholder="Search..." />
        </div>
    );

};

export default GlobalSearch;
