import { DOMAIN } from "@/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: DOMAIN,
    prepareHeaders: (headers) => {

        const token = window.localStorage.getItem("authToken");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;

    }
});

export const baseApi = createApi({
    reducerPath: "baseApi",
    baseQuery,
    tagTypes: ["me", "users", 'clients', 'professions', 'teams', 'projects'],
    endpoints: (builder) => ({})
});
