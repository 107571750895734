import React, { useEffect, useRef } from "react";
import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend, LinearScale, CoreChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, BarElement, Title, Tooltip, Legend, LinearScale);

interface ChartProps {
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor: string;
            borderColor: string;
            borderRadius: number;
            barPercentage: number;
            categoryPercentage: number;
        }[];
    };
}

const BarChart: React.FC<ChartProps> = ({ data }) => {

    return (
        <Bar
            data={data}
            options={{
                animation: { duration: 0 },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { display: false },
                    tooltip: { displayColors: false }
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#979797",
                            font: {
                                size: 11,
                                family: "Onest",
                                weight: 600
                            }
                        },
                        border: { display: false },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        ticks: { display: false },
                        grid: {
                            color: "#CECECE",
                            drawTicks: false
                        },
                        border: {
                            display: false,
                            dash: [4, 4]
                        },
                        beginAtZero: true
                    }
                }
            }}
            height={200}
        />
    );

};

export default BarChart;
