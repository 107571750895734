import AddIcon from "@/assets/img/ico-add.svg?react";
import ChevronDownIcon from "@/assets/img/ico-chevron-down.svg?react";
import ChevronNextIcon from "@/assets/img/ico-chevron-next.svg?react";
import ChevronPrevIcon from "@/assets/img/ico-chevron-prev.svg?react";
import { Tab, useTabs } from "@/hooks/useTabs";

import userImage from "@/assets/img/user-1.png";
import { format } from "date-fns";
import { useState } from "react";


import Spinner from "@/components/common/Spinner";
import { CalendarApi, DatesSetArg, ViewMountArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { routerConfig } from "@/routes";

interface CalendarEvent {
    id: string;
    title: string;
    start: string;
    extendedProps: {
        status: string;
        desk: string;
        users: string[];
    };
}

type ViewType = "dayGridMonth" | "timeGridWeek" | "multiMonthYear"
const tabs = [{ id: "dayGridMonth",
    label: "Month" }, { id: "timeGridWeek",
    label: "Week" }, { id: "multiMonthYear",
    label: "Year" }] as const;

const events = [{
    id: "1",
    title: "Project Name 1",
    start: "2024-12-05T10:00:00",
    end: "2024-12-05T12:00:00",
    extendedProps: {
        status: "info1",
        desk: "Conference Room",
        users: [userImage,
            userImage,
            userImage,
            userImage,
            userImage]
    }
}, {
    id: "2",
    title: "Project Name 1",
    start: "2024-12-07T10:00:00",
    end: "2024-12-07T12:00:00",
    extendedProps: {
        status: "info2",
        desk: "Conference Room",
        users: [userImage, userImage, userImage, userImage]
    }
}, {
    id: "3",
    title: "Project Name 1",
    start: "2024-12-05T15:00:00",
    end: "2024-12-05T17:00:00",
    extendedProps: {
        status: "info3",
        desk: "Conference Room",
        users: [userImage, userImage]
    }
}];


const SchedulingPage = () => {

    useBreadcrumbs([routerConfig.scheduling]);
    const [date, setDate] = useState(format(new Date(), "MMM yyyy"));
    const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);
    const calendarRef = useRef<FullCalendar>(null);

    const { activeTab, setActiveTab } = useTabs(tabs);


    const statusMap: Record<string, string> = {
        info1: "--info-1",
        info2: "--info-2",
        info3: "--info-3",
        info4: "--info-4",
        info5: "--info-5",
        info6: "--info-6",
        info7: "--info-7"
    };

    const renderEventContent = ({ event }: { event: CalendarEvent }) => {

        const { extendedProps } = event;
        const usersVisibleLength = 2;
        const hiddenUsersCount = Math.max(0, extendedProps.users.length - 1 - usersVisibleLength);

        const users = extendedProps.users.map((user, index) => {

            if (index <= usersVisibleLength) {

                return (
                    <li key={index} className="user-item">
                        <img src={user} alt={`User ${index}`} />
                    </li>
                );

            } else {

                return (
                    <li key={index} className="user-item" style={{ display: "none" }}>
                        <img src={user} alt={`Hidden User ${index}`} />
                    </li>
                );

            }

        });

        return (
            <div className={clsx("event-calendar", statusMap[extendedProps.status])}>
                <div className="event-calendar-status"></div>
                <div className="event-calendar-content">
                    <div className="event-calendar-heading">
                        <p className="event-calendar-title">{event.title}</p>
                        <p className="event-calendar-desk">{extendedProps.desk}</p>
                    </div>
                    <div className="event-calendar-footer">
                        <ul className="user-list">{users}</ul>
                        {hiddenUsersCount > 0 && <span className="users-counter">+{hiddenUsersCount}</span>}
                    </div>
                </div>
            </div>
        );

    };

    const handleDatesSet = ({ start, end, view }: DatesSetArg) => {

        let formattedDateRange = "";

        if (view.type === "dayGridMonth") {

            formattedDateRange = format(start, "MMMM yyyy");

        } else if (view.type === "timeGridWeek") {

            formattedDateRange = `${format(start, "MMM d")} – ${format(end, "MMM d, yyyy")}`;

        } else if (view.type === "multiMonthYear") {

            formattedDateRange = format(start, "yyyy");

        }

        setDate(formattedDateRange);

    };

    const handleDayHeaderContent = (args: { date: Date; view: { type: string } }) => {

        return args.view.type === "timeGridWeek"
            ? format(args.date, "EEE d")
            : format(args.date, "EEE");

    };

    const handleViewDidMount = (info: ViewMountArg) => {

        if (!calendarApi) return;
        const offset = calendarApi?.getDate().getTimezoneOffset() / 60;
        const sign = offset >= 0
            ? "+"
            : "-";
        const timeZone = `GMT${sign}${Math.abs(offset)}`;

        if (info.el) {

            const axisFrame = info.el.querySelector(".fc-timegrid-axis-frame");
            if (axisFrame) {

                axisFrame.innerHTML = timeZone;

            }

        }

    };

    const handleTabChange = (id: ViewType) => {

        setActiveTab(id);
        calendarApi?.changeView(id);

    };

    const handleToday = () => calendarApi?.today();
    const handlePrev = () => calendarApi?.prev();
    const handleNext = () => calendarApi?.next();

    useEffect(() => {

        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {

            setCalendarApi(calendarApi);

        }

    }, [calendarRef]);

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="calendar">
                    <div className="calendar-header">
                        <div className="c-h-item">
                            <label className="airpicker-init-class-fullcalendar">
                                <div className="airpicker-fullcalendar--val">{date}</div>
                                <span className="ico"><ChevronDownIcon /></span>
                            </label>
                            <div className="control-group btn btn--md" id="mode-days">
                                {tabs.map((tab) => <label key={tab.id} className="control-btn-wrpr btn--md">
                                    <input
                                        type="radio"
                                        name="period"
                                        id={tab.id}
                                        hidden
                                        checked={activeTab === tab.id}
                                        onChange={() => handleTabChange(tab.id as ViewType)}
                                    />
                                    <div className={`btn btn--xs control-btn-2 ${activeTab === tab.id
                                        ? "active"
                                        : ""}`} style={{ minWidth: "64px" }}>
                                        <span>{tab.label}</span>
                                    </div>
                                </label>)}
                            </div>
                        </div>
                        <div className="c-h-item">
                            <div className="flex items-center gap--xs">
                                <button onClick={handlePrev} type="button" className="btn btn--custom-1 btn--square btn--md rounded-full" id="prev">
                                    <span className="ico"><ChevronPrevIcon /></span>
                                </button>
                                <button onClick={handleToday} type="button" className="btn btn--custom-1 btn--md rounded-full" id="today">
                                    <span>Today</span>
                                </button>
                                <button onClick={handleNext} type="button" className="btn btn--custom-1 btn--square btn--md rounded-full" id="next">
                                    <span className="ico"><ChevronNextIcon /></span>
                                </button>
                            </div>
                            <button type="button" className="btn btn--primary-advance-1 btn--md rounded-full">
                                <span className="ico"><AddIcon /></span>
                                <span>Add Event</span>
                            </button>
                        </div>
                    </div>
                    <div className="calendar-body">

                        <FullCalendar
                            viewClassNames={"custom-calendar-init-class-1"}
                            height={"100%"}
                            allDaySlot={false}
                            handleWindowResize={true}
                            slotMinTime={"08:00:00"}
                            editable={false}
                            slotDuration="01:00:00"
                            slotLabelFormat={[{
                                hour: "numeric",
                                minute: "2-digit",
                                meridiem: false,
                                hour12: false,
                                omitZeroMinute: false
                            }]}
                            selectable={false}
                            headerToolbar={false}
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin]}
                            initialView={activeTab}
                            events={events}
                            eventContent={renderEventContent}
                            datesSet={handleDatesSet}
                            dayHeaderContent={handleDayHeaderContent}
                            viewDidMount={handleViewDidMount}
                            schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
                        />
                        {/* <div className='custom-calendar-init-class-1'>
                    </div> */}
                    </div>
                </div>
            </div>
        </main>
    );

};


export default SchedulingPage;
