import { ModalProps } from "@/components/modals/Modal";
import ClientData from "./components/ClientData";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";

import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import { Client } from "@/types";

interface NewClientModal extends ModalProps {
    data: Client
}

const completeActionButtons = [{
    text: "Add Project",
    handler: () => { }
}, {
    text: "Add Note",
    handler: () => { }
}];

const NewClientModal = ({ data, ...props }: NewClientModal) => {

    const { currentStage, handleCompleteClick, handleContinueClick, handleCancel } = useNewEntityModal();

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Client Created!"
            completeDescription="Congratulations on successfully creating a new client! You can now start managing their project or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
        >
            <ClientData data={data} handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );

};

export default NewClientModal;
