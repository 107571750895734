import GoogleIcon from "@/assets/img/ico-google.svg?react";
import Logo from "@/assets/img/login-logo.svg?react";

const FormHeader = () => {

    return (
        <div className="form-header">
            <div className="logo mx-auto">
                <Logo />
            </div>
            <div className="heading-block text-center gap--md">
                <h3 className="heading">Welcome Back, Get Login</h3>
                <p className="color-control-3 text--md">
                    Don’t have account?{" "}
                    <a href="#" className="btn btn-link color-primary tdu">Create an Account</a>
                </p>
            </div>
            <div className="flex flex-col gap--sm">
                <a href="#" className="btn btn--custom-2 btn--xl rounded-full">
                    <span className="ico"><GoogleIcon /></span>
                    <span>Login with Google</span>
                </a>
            </div>
        </div>
    );

};

export default FormHeader;
