import React, { useEffect, useRef } from "react";
import "air-datepicker/air-datepicker.css";
import AirDatepicker from "air-datepicker";

// Определяем типы для пропсов
interface DatePickerProps {
  onDateSelect?: (date: Date) => void; // Колбэк при выборе даты
  options?: Partial<AirDatepicker.Options>; // Настройки для air-datepicker
}

const localeEn = {
    days: ["Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"],
    daysShort: ["Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"],
    daysMin: ["Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"],
    months: ["January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"],
    monthsShort: ["Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"],
    today: "Today",
    clear: "Clear",
    dateFormat: "MM/dd/yyyy",
    timeFormat: "hh:mm aa",
    firstDay: 0
};

const DatePickerInline: React.FC<DatePickerProps> = ({ onDateSelect, options = {} }) => {

    const datepickerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (datepickerRef.current) {

            // Инициализация air-datepicker
            const instance = new AirDatepicker(datepickerRef.current, {
                onSelect: ({ date }: { date: Date }) => {

                    if (onDateSelect) onDateSelect(date); // Вызов колбэка при выборе даты

                },
                inline: true,
                locale: localeEn,
                onRenderCell ({ date, cellType }) {

                    const eventsObj = {
                        1: {
                            events: [{
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }]
                        },
                        2: {
                            events: [{
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }]
                        },
                        11: {
                            events: [{
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }]
                        },
                        12: {
                            events: [{
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }]
                        },
                        16: {
                            events: [{
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }, {
                                content: "Lorem"
                            }]
                        }
                    };

                    const isDay = cellType === "day";
                    if (isDay) {

                        const _date = date.getDate();
                        let shouldChangeContent = false;
                        const dotHtml = "<span class=\"date-status text-center\">&#8226;</span>";
                        const maxRenderEvent = 3;
                        if (isDay && eventsObj[_date]) {

                            shouldChangeContent = true;

                        }


                        return {

                            html: `

            <div class="date-block">
                ${shouldChangeContent
                        ? `<div class="flex flex-col justify-center items-center">
                        <span class="date-numb text-center">
                            ${_date}
                        </span>

                        <div class="date-status-list">
                        ${eventsObj[_date].events.map((item, index) => {

                                if (index + 1 <= maxRenderEvent) {

                                    return dotHtml;

                                } else {

                                    return "";

                                }

                            }).join("")
                        }

                        </div>
                    </div>`
                        : `<span class="date-numb text-center">
                        ${_date}
                    </span>`
                }
            </div>`

                        };

                    } else {

                        return date;

                    }

                },
                ...options // Передача дополнительных опций
            });

            // Очистка экземпляра при размонтировании
            return () => {

                instance.destroy();

            };

        }

    }, [onDateSelect, options]);

    return <div className="calendar-inline" ref={datepickerRef} />;

};

export default DatePickerInline;
