import Spinner from "@/assets/img/spinner.svg?react";

const Loader = ({ size }: { size?: number }) => {

    return (
        <Spinner width={size} height={size} />
    );

};

export default Loader;
