import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";
import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import ProjectData from "./components/ProjectData";

interface NewProjectModal extends ModalProps {
}

const completeActionButtons = [{ text: "Assign Team",
    handler: () => { } }, { text: "Add Task",
    handler: () => { } }];

const NewProjectModal = ({ ...props }: NewProjectModal) => {

    const { currentStage,
            handleCompleteClick,
            handleContinueClick,
            handleCancel,
            handleGoToSelection,
    } = useNewEntityModal();

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New Project Created!"
            completeDescription="Congratulations on successfully creating a new project! You can now start managing task, assign team or return to the home page."
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
            handleBackToSelectionClick={handleGoToSelection}
            handleCompleteClick={handleCompleteClick}
        >
            <ProjectData handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );

};

export default NewProjectModal;
