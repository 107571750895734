import { ClientType, ContactMethod, RiskLevel, Status } from "@/types/emums";
import * as yup from "yup";
import { addressSchema } from "./addressSchema";

export const clientSchema = yup.object().shape({
    isNew: yup.boolean().required("isNew status is required"),
    id: yup.number().required("ID is required"),
    userId: yup.number().required("User ID is required"),
    addressId: yup.number().optional(),
    photo: yup.mixed().optional(),
    clientType: yup.string().oneOf(Object.values(ClientType)).required("Client type is required"),
    riskLevel: yup.string().oneOf(Object.values(RiskLevel)).required("Risk level is required"),
    fullName: yup.string().required("Full name is required"),
    companyName: yup.string().required(),
    insurance: yup.boolean().required("Insurance status is required"),
    status: yup.string().oneOf(Object.values(Status)).required("Status is required"),
    email: yup.string().email("Invalid email format").required("Email is required"),
    phone: yup.string().required("Phone is required"),
    phoneCountry: yup.string().required("Phone country code is required"),
    contactMethod: yup.string().oneOf(Object.values(ContactMethod)).required("Contact method is required"),
    createdAt: yup.string().required("Creation date is required"),
    updatedAt: yup.string().required("Update date is required"),
    address: addressSchema,
});

export type ClientFormRequest = yup.InferType<typeof clientSchema>;
