import { OptionCustomProps } from "@/components/common/Select";
import { ClientType, ContactMethod, RiskLevel, Status } from "@/types/emums";
import { contactMethodMap, riskLevelMap, statusMap } from ".";
import { City, Country, State } from "country-state-city";

export const statusOptions: OptionCustomProps<Status>[] = Object.values(Status).map(value => ({
    label: statusMap[value].label,
    value: value
}));

export const insuranceOptions: OptionCustomProps<boolean>[] = [true, false].map(value => ({
    label: value ? 'Yes' : 'No',
    value: value
}));

export const clientTypeOptions: OptionCustomProps<ClientType>[] = Object.values(ClientType).map(value => ({
    label: value,
    value: value
}));
export const projectTypeOptions: OptionCustomProps<string>[] = ["Residential Remodel",
    "Commercial Remodel",
    "New Construction",
    "Interior Design",
    "Landscape Design",
    "Kitchen Remodel",
    "Bathroom Remodel",
    "Office Space",
    "Retail Space",
    "Hospitality Design",
    "Urban Planning",
    "Mixed Use"].map(value => ({
        label: value,
        value: value
    }));

export const riskLevelOptions: OptionCustomProps<RiskLevel>[] = Object.values(RiskLevel).map(value => ({
    label: riskLevelMap[value].label,
    value: value
}));
export const contactMethodOptions: OptionCustomProps<ContactMethod>[] = Object.values(ContactMethod).map(value => ({
    label: contactMethodMap[value],
    value: value
}));
export const departmentOptions = [{
    label: "Los Angeles, CA",
    value: 1
}];


export const countryOptions = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode
}));
export const stateOptions = (country: string) => State.getStatesOfCountry(country).map((state) => ({
    label: state.name,
    value: state.isoCode
}));
export const cityOptions = (country: string, state: string) => City.getCitiesOfState(country, state).map((city) => ({
    label: city.name,
    value: city.name
}));