import AddIcon from "@/assets/img/ico-add.svg?react";
import DatepickerOutline from "@/components/common/DatepickerOutline";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import TabNavigation from "@/components/common/TabNavigation";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import clsx from "clsx";
import TeamsTab from "./tabs/TeamsTab";
import UsersTab from "./tabs/UsersTab";
import useModal from "@/hooks/useModal";


const tabs = [{
    id: "teams",
    label: "Teams"
}, {
    id: "users",
    label: "Users"
}] as const;


const TeamManagementPage = () => {
    const { openModal } = useModal();
    useBreadcrumbs([routerConfig.team]);
    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();


    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <DatepickerOutline />
                                <span className="option-separator"></span>
                                <TabNavigation
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    renderTabCount={() => 3}
                                />
                            </div>
                            <div className="heading-option">
                                <ViewModeSwitcher />
                                <span className="option-separator"></span>
                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <button onClick={() => openModal("newTeam")} type="button" className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><AddIcon /></span>
                                        <span>New Team</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className="view-page-block tabs-fullpage active-page">
                            <div className={clsx("tab", { "active-tab": activeTab === "teams" })}>
                                <TeamsTab {...{
                                    isGridView,
                                    isTableView
                                }} />
                            </div>
                            <div className={clsx("tab", { "active-tab": activeTab === "users" })}>
                                <UsersTab {...{
                                    isGridView,
                                    isTableView
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

};

export default TeamManagementPage;
