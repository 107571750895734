import Modal, { ModalProps } from "@/components/modals/Modal"
import { Client } from "@/types"
import { useCallback } from "react"
import ClientData from "../NewClientModal/components/ClientData"

interface EditClientModal extends ModalProps {
    data: Client
}

const EditClientModal: React.FC<EditClientModal> = ({ data, ...props }) => {
    const handleCancel = useCallback(() => {
        props.onClose();
    }, []);

    return (
        <Modal {...props}
            closeOnClickOutside={false}
            dialogClassName={"modal__dialog--700"}
            contentClassName={"modal--rounded"}
        >
            <ClientData data={data} handleCancel={handleCancel} handleCompleteClick={handleCancel} />
        </Modal>
    )
}

export default EditClientModal
