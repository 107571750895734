
import BoxIcon from "@/assets/img/ico-box.svg?react";
import CopyIcon from "@/assets/img/ico-copy.svg?react";
import DocIcon from "@/assets/img/ico-doc.svg?react";
import FireIcon from "@/assets/img/ico-fire.svg?react";
import UserIcon from "@/assets/img/ico-user.svg?react";
import WidgetCard from "@/components/cards/WidgetCard";
import ActivityChart from "@/components/charts/ActivityChart";
import DocumentsList from "@/components/common/DocumentsList";
import userImage1 from "@/assets/img/user-1.png";
import projectImage from "@/assets/img/project-img-1.jpg";
import TaskCard from "@/components/cards/TaskCard";
import Swiper from "@/components/common/Swiper";

import NodesIcon from "@/assets/img/ico-nodes.svg?react";
import ChatIcon from "@/assets/img/ico-chat.svg?react";
import ModuleIcon from "@/assets/img/ico-module.svg?react";
import CrownIcon from "@/assets/img/ico-crown.svg?react";
import OclockIcon from "@/assets/img/ico-oclock.svg?react";
import { ReactNode } from "react";

const Overview = () => {

    return (
        <div className="block-container tab-body-fullpage">
            <div className="row gutters-3">
                <div className="col-xl-4 col-lg-6 col-md-12 flex flex-col">
                    <AboutUser />
                </div>
                <div className="col-xl-8 col-lg-6 col-md-12 flex flex-col">
                    <div className="row gutters-3">
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <CurrentProject />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <CurrentTasks />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <Activity />
                        </div>
                        <div className="col-xl-6 col-lg-12 flex flex-col">
                            <PersonalSkills />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


function AboutUser () {

    return (
        <WidgetCard title="About Team" showEdit>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">
                    <table className="table table-2" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Status</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className="pill-custom pill--custom-1 pill--sm">
                                        <span>Active</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Role</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className="pill-custom pill--custom-3 pill--sm">
                                        <span>Interior Designer</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Email</p></td>
                                <td style={{ width: "60%" }}>
                                    <a href="#" className="btn btn-link color-secondary">
                                        <span className="font-400">james.cole@example.com</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Phone Number</p></td>
                                <td style={{ width: "60%" }}>
                                    <a href="#" className="btn btn-link color-secondary">
                                        <span className="font-400">(646) 555-0198</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Department</p></td>
                                <td style={{ width: "60%" }}>Los Angeles, CA</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Team</p></td>
                                <td style={{ width: "60%" }}>
                                    <div className="pill-custom pill--custom-3 pill--sm">
                                        <span>Concept Design Team</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Projects</p></td>
                                <td style={{ width: "60%" }}>8</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Tasks</p></td>
                                <td style={{ width: "60%" }}>12</td>
                            </tr>
                            <tr>
                                <td style={{ width: "40%" }}><p className="color-control-3
                                                                table-text-nowrap">Date Joined</p></td>
                                <td style={{ width: "60%" }}>Oct 12, 2024</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </WidgetCard>
    );

}

function CurrentProject () {

    return (
        <WidgetCard title="Current Project">
            <div className="card-details-wrpr btn btn--xs">
                <div className="card-details--item">
                    <div className="card-details--head items-center">
                        <div className="user-option-block user-option-block--sm">
                            <div className="user-block-relatives">
                                <div className="ico ico--lg user-block-img-wrpr">
                                    <img src={projectImage} alt="project" />
                                </div>
                                <div className="ico user-ico-hot-wrpr">
                                    <span className="ico user-ico-hot --active">
                                        <FireIcon />
                                    </span>
                                </div>
                            </div>

                            <div className="user-block-content-info">
                                <div className="user-block-titled-info">
                                    <p className="heading user-block-title text--lg">Project Name 1</p>
                                </div>
                                <div className="user-block-desk-info">
                                    <p className="user-project--text">Client Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-details--body">
                        <div className="presence-sections-wrpr">
                            <div className="presence-titled-row">
                                <p className="precence-title">Customer Agreement</p>
                                <p className="precence-dates">due to <span>01.02.2024</span></p>
                            </div>
                            <div className="presence-sections">
                                <div className="presence-section" style={{ backgroundColor: "var(--color-status-1)" }}></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                                <div className="presence-section"></div>
                            </div>
                        </div>
                        <ul className="project-list">
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <CopyIcon />
                                    </span>
                                    <p className="project-variable--text">Tasks:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">12 / 32</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <DocIcon />
                                    </span>
                                    <p className="project-variable--text">Documents:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">3</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <BoxIcon />
                                    </span>
                                    <p className="project-variable--text">Materials:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">123</p>
                                </div>
                            </li>
                            <li className="project-list-item">
                                <div className="project-variable-wrpr">
                                    <span className="ico project-variable--ico">
                                        <UserIcon />
                                    </span>
                                    <p className="project-variable--text">Users:</p>
                                </div>
                                <div className="project-value-wrpr">
                                    <p className="project-value--text">24 users</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </WidgetCard>
    );

}

function CurrentTasks () {

    const data = [{
        priority: "High Priority",
        title: "Install wall cabinets",
        dueDate: "Nov 10, 2024",
        progress: 0,
        totalProgress: 12,
        documents: 5,
        comments: 5,
        users: [userImage1, userImage1]
    },
    {
        priority: "High Priority",
        title: "Install wall cabinets",
        dueDate: "Nov 10, 2024",
        progress: 0,
        totalProgress: 12,
        documents: 5,
        comments: 5,
        users: [userImage1, userImage1]
    },
    {
        priority: "High Priority",
        title: "Install wall cabinets",
        dueDate: "Nov 10, 2024",
        progress: 0,
        totalProgress: 12,
        documents: 5,
        comments: 5,
        users: [userImage1, userImage1]
    },
    {
        priority: "High Priority",
        title: "Install wall cabinets",
        dueDate: "Nov 10, 2024",
        progress: 0,
        totalProgress: 12,
        documents: 5,
        comments: 5,
        users: [userImage1, userImage1]
    },
    {
        priority: "High Priority",
        title: "Install wall cabinets",
        dueDate: "Nov 10, 2024",
        progress: 0,
        totalProgress: 12,
        documents: 5,
        comments: 5,
        users: [userImage1, userImage1]
    }];
    return (
        <WidgetCard title="Current Tasks">
            <Swiper
                slides={data.map((item, index) => <TaskCard key={index} data={item} />)}
            />
        </WidgetCard>
    );

}


function Activity () {

    const data = [{
        Mon: 25
    },
    {
        Tue: 50
    },
    {
        Wed: 0
    },
    {
        Thu: 0
    },
    {
        Fri: 0
    }];
    return (
        <WidgetCard title="Activity" subtitle={(<p className="chart-range-dates">01 Oct - 05 Oct</p>)}>
            <div className="card-details-wrpr btn btn--xs">
                <ActivityChart
                    units="%"
                    data={data}
                    maskDayOfWeek="EEE"
                />
            </div>
        </WidgetCard>
    );

}

function PersonalSkills () {

    const skills = ["Project Management",
        "Communication",
        "Problem Solving",
        "Team Leadership",
        "Time Management"];
    const iconsMap: Record<(typeof skills)[number], ReactNode> = {
        "Project Management": <NodesIcon />,
        Communication: <ChatIcon />,
        "Problem Solving": <ModuleIcon />,
        "Team Leadership": <CrownIcon />,
        "Time Management": <OclockIcon />
    };
    return (
        <WidgetCard title="Personal Skills" showAdd showEdit>
            <div className="table-custom table-custom-2">
                <div className="table-custom-body">

                    <div className="row gutters-1">
                        {skills.map((item, index) => <div key={index} className="col-md-6 col-sm-6 col-12">
                            <a href="#" className="skill-widget btn">
                                <p className="skill-title">{item}</p>
                                <span className="ico skill-ico">{iconsMap[item]}</span>
                            </a>
                        </div>)}
                    </div>
                </div>
            </div>
        </WidgetCard>
    );

}


export default Overview;
