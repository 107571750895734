import { Client, ClientProject, Project } from "@/types";
import user1 from "@/assets/img/user-1.png";
import user2 from "@/assets/img/user-img.png";
import projectPhoto from "@/assets/img/project-img-2.jpg";

export const projects: ClientProject[] = [{
    id: 1,
    projectName: "Modern Kitchen Remodel",
    address: "8502 Preston Rd. Inglewood, Maine",
    userName: "John Doe",
    users: [{
        id: 1,
        fullName: "John Doe",
        photo: user1
    }, {
        id: 2,
        fullName: "Jane Smith",
        photo: user2
    }],
    progress: {
        completed: 12,
        total: 32
    },
    documentCount: 3,
    materialsCount: 123,
    status: "expedition",
    desRetainer: 5000,
    estVolume: 350000,
    type: "Project Type",
    photo: projectPhoto
}, {
    id: 2,
    projectName: "Office Building Renovation",
    address: "123 Main St. Springfield, IL",
    userName: "Alice Johnson",
    users: [{
        id: 3,
        fullName: "Alice Johnson",
        photo: user1
    }, {
        id: 4,
        fullName: "Bob Williams",
        photo: user2
    }],
    progress: {
        completed: 15,
        total: 50
    },
    documentCount: 5,
    materialsCount: 150,
    status: "agreement",
    desRetainer: 5000,
    estVolume: 350000,
    type: "Project Type",
    photo: projectPhoto
}, {
    id: 3,
    projectName: "Luxury Villa Construction",
    address: "789 Ocean Blvd. Malibu, CA",
    userName: "Eve Brown",
    users: [{
        id: 5,
        fullName: "Eve Brown",
        photo: user1
    }],
    progress: {
        completed: 30,
        total: 60
    },
    documentCount: 1,
    materialsCount: 224,
    status: "completed",
    desRetainer: 5000,
    estVolume: 350000,
    type: "Project Type",
    photo: projectPhoto
}];

export const clients: Client[] = [{
    id: 1,
    photo: user1,
    name: "Client Name 1",
    isHighPriority: true,
    isNew: true,
    address: "Los Angeles, CA",
    email: "alma.lawson@example.com",
    phone: "(217) 555-0113",
    projects,
    activeProjects: 1,
    completedProjects: 3,
    status: "active"
}, {
    id: 2,
    photo: user2,
    name: "Client Name 2",
    isHighPriority: false,
    isNew: true,
    address: "New York, NY",
    email: "james.cole@example.com",
    phone: "(646) 555-0198",
    projects,
    activeProjects: 8,
    completedProjects: 3,
    status: "inactive"
}, {
    id: 3,
    photo: user2,
    name: "Client Name 3",
    isHighPriority: false,
    isNew: false,
    address: "Miami, FL",
    email: "matthew.turner@example.com",
    phone: "(305) 555-0172",
    projects,
    activeProjects: 5,
    completedProjects: 3,
    status: "active"
}];

export const tabs = [{
    id: "all-clients",
    label: "All Clients"
}, {
    id: "new-clients",
    label: "New Clients"
}, {
    id: "high-priority",
    label: "High Priority"
}];

type TabId = typeof tabs[number]["id"]
export const tabFields: Record<TabId, string | null> = {
    "all-clients": null,
    "new-clients": "isNew",
    "high-priority": "isHighPriority"
};
