import ExportIcon from "@/assets/img/ico-export.svg?react";
import DatepickerOutline from "@/components/common/DatepickerOutline";
import Filters from "@/components/common/Filters";
import Search from "@/components/common/Search";
import TabNavigation from "@/components/common/TabNavigation";
import ViewModeSwitcher, { useViewMode } from "@/components/common/ViewModeSwitcher";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useTabs } from "@/hooks/useTabs";
import { routerConfig } from "@/routes";
import Budget from "./tabs/Budget";
import Financial from "./tabs/Financial";
import TeamPerformance from "./tabs/TeamPerformance";


const tabs = [{
    id: "financial",
    label: "Financial"
}, {
    id: "team-performance",
    label: "Team Performance"
}, {
    id: "budget",
    label: "Budget"
}];
type TabId = typeof tabs[number]["id"];

const ReportsPage = () => {

    useBreadcrumbs([routerConfig.reports]);
    const { activeTab, setActiveTab } = useTabs(tabs);
    const { isTableView, isGridView } = useViewMode();

    const tabComponents: Record<TabId, JSX.Element> = {
        financial: <Financial isGirdView={isGridView} isTableView={isTableView} />,
        "team-performance": <TeamPerformance isGirdView={isGridView} isTableView={isTableView} />,
        budget: <Budget isGirdView={isGridView} isTableView={isTableView} />
    };

    const renderTab = () => {

        return tabComponents[activeTab];

    };

    return (
        <main className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="rectangle-block-containers block-container-fullpage tabs">
                    <div className="block-container navigation-block">
                        <div className="heading--row">
                            <div className="heading-option">
                                <DatepickerOutline />
                                <span className="option-separator"></span>
                                <TabNavigation
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    renderTabCount={() => 3}
                                />
                            </div>
                            <div className="heading-option">
                                <ViewModeSwitcher />
                                <span className="option-separator"></span>
                                <div className="flex-controls gap--xs">
                                    <Filters />
                                    <Search />
                                    <a href="#" className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class">
                                        <span className="ico"><ExportIcon /></span>
                                        <span>Export</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-container page-results">
                        <div className={"view-page-block tabs-fullpage active-page"}>
                            <div className="tab active-tab">
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );

};


export default ReportsPage;
