import { LoginRequest } from "@/types/request";
import * as yup from "yup";

export const loginSchema: yup.ObjectSchema<LoginRequest> = yup.object({
    email: yup.string().email("Invalid email format").
        required("Email is required"),
    password: yup.string().min(6, "Password must be at least 6 characters").
        required("Password is required")
});

