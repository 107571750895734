import { VoidFunctionWithoutArguments } from "@/types";
import clsx from "clsx";

interface OptionSelector {
    isActive: boolean;
    icon: React.ReactElement
    text: string;
    handleClick: VoidFunctionWithoutArguments;
}

const OptionSelector: React.FC<OptionSelector> = ({
    isActive,
    icon,
    text,
    handleClick
}) => {

    return (
        <div
            className={clsx("border-1 selection-tab rounded-12", {
                "border-pale": !isActive,
                "border-secondary": isActive
            })}
            onClick={handleClick}
        >
            <div className="rounded-full selection-icon shadow-1 border-0.5 mb-4">{icon}</div>

            <p className="text-md">{text}</p>

        </div>
    );

};

export default OptionSelector;
