import Select, { OptionCustomProps } from "@/components/common/Select";
import { useGetAllUsersQuery } from "@/services/usersApi";
import { VoidFunctionWithoutArguments } from "@/types";
import { ClientFormRequest } from "@/validation/clientSchema";
import { UseFormReturn } from "react-hook-form";

interface AccountManagerProps {
    formState: UseFormReturn<ClientFormRequest>,
}

const AccountManager = ({ formState }: AccountManagerProps) => {
    const { data: response } = useGetAllUsersQuery()
    const { register, watch, setValue, formState: { errors, defaultValues } } = formState;

    const accountManagerOptions: OptionCustomProps<number>[] = response?.data?.map(user => ({ label: user.firstName, value: user.id })) || []

    const handleAccountManagerChange = (option: OptionCustomProps<number>) => {

        setValue("userId", option.value);

    };
    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Account Manager</span>
                    </div>
                    <div className="col-8">
                        <Select
                            options={accountManagerOptions}
                            onChange={handleAccountManagerChange}
                            value={watch("userId")}
                            placeholder="Select user"
                            error={errors.userId?.message}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default AccountManager;
