import React, { useState } from 'react'
import CloudUploadIcon from "@/assets/img/ico-cloud-upload.svg?react";
import Button from './Button';

interface DragFileInputProps {
    isMultiple?: boolean;
    title?: string;
    externalHandler?: (files: File[]) => void;
}

const DragFileInput: React.FC<DragFileInputProps> = ({
    isMultiple = true,
    title,
    externalHandler,
}) => {
    const [files, setFiles] = useState<FileList | Array<undefined>>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setFiles(event.target.files);

        if (externalHandler) {
            externalHandler(Array.from(event.target.files));
        }
    }

    return (
        <div className='drag-input_container'>
            <div className='drag-input_label'>
                <div className='drag-input_text'>
                    <span className='drag-input_upload-icon'>
                        <CloudUploadIcon />
                    </span>

                    <span>{title || "Drag & Drop document(s) here or"}</span>
                </div>

                <Button
                    type="button"
                    className="btn btn--primary-advance-1 btn--md rounded-full btn--dop-class"
                >
                    <span className='ps-4 pe-4'>Upload</span>
                </Button>
            </div>

            <input
                type='file'
                id='drag'
                className='drag-input_input'
                multiple={isMultiple}
                onChange={handleChange}
                accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
            />

            {/* {files.length > 0 &&
                <p className='drag-input_file-name'>
                    Last uploaded {files[files.length - 1].name}
                </p>
            } */}
        </div>
    )
}

export default DragFileInput
