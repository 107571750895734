import { Link } from "react-router"
import TeamIcon from "@/assets/img/ico-team.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import projectImage from "@/assets/img/project-ico.jfif";
import { Team, TeamData } from "@/types";
import userImage from "@/assets/img/img-user-1.png";
import DropdownActions from "@/components/common/DropdownActions";
import { getFullUrl } from "@/utils";
import { routerConfig } from "@/routes";
import Image from "@/components/common/Image";

interface TeamCardProps {
    data: Team,
    actions: Function;
}

const TeamCard: React.FC<TeamCardProps> = ({ data, actions }) => {
    return (

        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">

            <div className="card-widget card-widget-product w-full h-full">
                <div className="card-widget-body">
                    <div className="card-details-wrpr">
                        <div className="card-details--item">
                            <div className="card-details--head">
                                <div className="user-option-block user-option-block--sm">
                                    <div className="user-block-relatives">
                                        <div className="ico ico--lg user-block-img-wrpr rounded-full">
                                            <span className="ico user-black-img--ico-2">
                                                <TeamIcon />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-details-actions">
                                    <DropdownActions actions={actions(data)} />
                                </div>
                            </div>
                            <div className="card-details--body">
                                <div className="card-details-heading">
                                    <p className="heading user-block-title text--xl">
                                        {data.name}
                                    </p>
                                </div>
                                <div className="card-details--head card-details--users-row">
                                    <div className="user-list-wrpr p-0 btn--xs btn">
                                        <ul className="user-list">
                                            {data.members.map(member =>
                                                <li className="user-item">
                                                    <Image src={member.photo || userImage} alt="user" />
                                                </li>)
                                            }
                                        </ul>
                                        <p className="users-counter-2"><span>{data.members?.length || 12}</span> users</p>
                                    </div>
                                    <a href="#" className="btn btn--primary-advance-1 btn--xs rounded-full btn--square">
                                        <span className="ico"><AddIcon /></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card-details--item">
                            <div className="card-details--body">
                                <div className="card-details--projects-info-wrpr">
                                    <div className="card-details--head card-details--project-row">
                                        <ul className="counter-projects-list">
                                            <li className="counter-project-item">
                                                <div className="counter-project-text">
                                                    <div className="pill-custom pill--xs">{data.projects.length}</div>
                                                    <p className="counter-project-value">Projects:</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <a href="#" className="btn btn-link color-secondary tdu">
                                            <span>All Projects</span>
                                        </a>
                                    </div>
                                    <div className="card-details--projects-info--body">
                                        <ul className="card-details--projects-info-list">
                                            {Array.from({ length: data.projects.length }).map((_, index) => <li key={index} className="card-details--project-info--item">
                                                <a href="#" className="btn btn--project-row">
                                                    <div className="project-titled-wrpr">
                                                        <div className="ico project-ico">
                                                            <img src={projectImage} alt="project" />
                                                        </div>
                                                        <div className="project-info-wrapper-block">
                                                            <div className="project-info-item-block --only">
                                                                <p className="project-title">Project Name 1</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`event-project-status --info-${index + 1}`}></div>
                                                </a>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>

                                <div className="card-details--actions">
                                    <div className="card-details--action-item">
                                        <Link to={getFullUrl([routerConfig.team.path, data.id])} className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                            <span>Show More</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamCard
