import { Document } from "@/types";
import { ProjectFormRequest } from "@/validation/projectSchema";
import { Controller, UseFormReturn } from "react-hook-form";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import DragFileInput from "@/components/common/DragFileInput";
import DocumentsTable from "@/components/common/DocumentsTable";


interface ContractProps {
    formState: UseFormReturn<ProjectFormRequest>
}

const Contract = ({ formState }: ContractProps) => {

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const actions = (arg: Document) => [{
        label: "Edit",
        icon: <EditIcon />,
        fn: () => console.log(arg)
    }];

    const contracts: Array<File> | string[] = watch('contracts');

    const contractsList = contracts ? Array.from(contracts)
        .map((contractFile, i) => ({id: i, name: typeof contractFile === 'string' 
                ? contractFile 
                : contractFile.name
            })) : [];

    return (
        <div className="row g-20">
            <div className="col-12">
                <Controller 
                    name='contracts'
                    control={control}
                    render={({ field: { value, onChange } }) => 
                        <DragFileInput externalHandler={onChange} />
                    }
                />

                <DocumentsTable 
                    data={contractsList} 
                    actions={actions} 
                />
            </div>
        </div>
    );

};
export default Contract;
