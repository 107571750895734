import ScheduleTimeline from "@/components/features/ScheduleTimeline";
import React from "react";

const Timeline = () => {

    return (
        <div className="block-container tab-body-fullpage p-0 relative">
            <ScheduleTimeline />
        </div>
    );

};

export default Timeline;
