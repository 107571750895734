import React from "react";
import Modal, { ModalProps } from "./Modal";

import CloseIcon from "@/assets/img/ico-close.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import CalendarIcon from "@/assets/img/ico-calendar-3.svg?react";
import DotsIcon from "@/assets/img/ico-move-dots.svg?react";
import EditIcon from "@/assets/img/ico-edit.svg?react";
import userImage from "@/assets/img/img-user-2.png";
import { Task } from "@/types";

interface TaskModalProps extends ModalProps {
    data: Task
}

const TaskModal = ({ ...props }: TaskModalProps) => {

    return (
        <Modal
            wrapperClassName="modal-full modal-right modal-none-padding modal--right-rounder"
            contentClassName="ovh"
            {...props}
        >
            <div className="modal__header modal__header--xs">
                <div className="heading--row">
                    <div className="heading-option">
                        <div className="flex-controls gap--md">
                            <a href="#" className="btn btn--primary-advance-1 btn--xs rounded-full btn--dop-class">
                                <span>Change Status</span>
                            </a>
                        </div>
                    </div>
                    <div className="heading-option">
                        <div className="flex-controls gap--md">
                            <a href="#" className="btn btn--custom-1 btn--xs rounded-full btn--square">
                                <span className="ico"><EditIcon /></span>
                            </a>
                            <button onClick={props.onClose} className="btn btn--custom-1 btn--xs rounded-full btn--square">
                                <span className="ico"><CloseIcon /></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__body modal__body__simple flex-auto">
                <div className="block-container">
                    <div className="task-modal-details">
                        <div className="task-modal-detail-item">
                            <div className="heading-block gap--xs">
                                <h4 className="heading">Prepare site for flooring installation</h4>
                            </div>
                        </div>
                        <div className="task-modal-detail-item">
                            <ul className="task-modal-detail-list">

                                <li className="task-modal-detail-item">
                                    <div className="task-modal-detail-text-wrpr">
                                        <p className="task-modal-detail-title">Status:</p>
                                        <div className="pill-custom pill--custom-7 pill--sm">
                                            <span>In Progress</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="task-modal-detail-item">
                                    <div className="task-modal-detail-text-wrpr">
                                        <p className="task-modal-detail-title">Assignee:</p>
                                        <div className="task-modal-detail-add-wrpr">
                                            <a href="#" className="btn btn--custom-1
                                                btn--xs rounded-full btn--square">
                                                <span className="ico"><AddIcon /></span>
                                            </a>
                                            <div className="user-list-wrpr">
                                                <ul className="user-list">
                                                    <li className="user-item"><img src={userImage} alt="user" /></li>
                                                    <li className="user-item"><img src={userImage} alt="user" /></li>
                                                    <li className="user-item"><img src={userImage} alt="user" /></li>
                                                    <li className="user-item"><img src={userImage} alt="user" /></li>
                                                </ul>
                                                <p className="users-counter-2">+<span>12</span> users</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="task-modal-detail-item">
                                    <div className="task-modal-detail-text-wrpr">
                                        <p className="task-modal-detail-title">Due date:</p>
                                        <div className="task-modal-detail-add-wrpr">
                                            <a href="#" className="btn btn--custom-1
                                                btn--xs rounded-full btn--square">
                                                <span className="ico"><CalendarIcon /></span>
                                            </a>
                                            <p className="due-date--text">Nov 30, 2024</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="task-modal-detail-item">
                                    <div className="task-modal-detail-text-wrpr">
                                        <p className="task-modal-detail-title">Label:</p>
                                        <div className="task-modal-detail-add-wrpr">
                                            <a href="#" className="btn btn--custom-1 btn--xs rounded-full btn--square">
                                                <span className="ico"><AddIcon /></span>
                                            </a>
                                            <div className="pill-labels-details">
                                                <div className="pill-custom pill--custom-10 pill--xs">
                                                    <span>Med Priority</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="task-modal-detail-item">
                            <div className="heading-block gap--md">
                                <div className="flex items-center gap--xs">
                                    <p className="font-500 text--sm">Description</p>
                                    <a href="#" className="btn btn-link color-secondary">
                                        <span className="ico"><EditIcon /></span>
                                    </a>
                                </div>
                                <p className="task-details-desk">
                                    This involves clearing the designated space of furniture,
                                    ensuring a level and clean surface, and verifying that all
                                    necessary subfloor adjustments have been completed
                                    to ensure a successful flooring installation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabs">
                    <div className="tabs-row-wrpr block-container">
                        <div className="tabs-navigation">
                            <div className="tabs-navigation-list">
                                <a href="#progress" data-tab-link
                                    className="btn btn--xl tab-custom-btn active-tab">
                                    <span>Progress</span>
                                </a>
                                <a href="#files" data-tab-link className="btn btn--xl tab-custom-btn">
                                    <span>Files</span>
                                </a>
                                <a href="#comments" data-tab-link className="btn btn--xl tab-custom-btn">
                                    <span>Comments</span>
                                    <div className="pill-custom pill--xs">12</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="tabs-body-content">
                        <div className="tab active-tab" data-id="progress">
                            <div className="block-container">
                                <div className="progress-tasks-wrpr">
                                    <div className="progress-tasks-header">
                                        <div className="progress-tasks-titled">
                                            <p className="progress-tasks-title">Progress List</p>
                                            <div className="progress-wrpr" style={{ "--filled-value--prc": 40 } as React.CSSProperties}>
                                                <svg className="doughnut-progress ico">
                                                    <circle className="doughnut-wrpr"></circle>
                                                    <circle className="doughnut-line-progress"></circle>
                                                </svg>
                                                <div className="progress-texts">
                                                    <span className="progress-completed text--lg">4</span>
                                                    <span className="progress-left text--lg">/12</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress-tasks-actions">
                                            <a href="#" className="btn btn--custom-1 btn--xs rounded-full btn--square">
                                                <span className="ico"><EditIcon /></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="progress-tasks-body">
                                        <ul className="progress-tasks-list">
                                            {Array.from({ length: 5 }).map((_, index) => <li key={index} className="progress-task-item">
                                                <div className="progress-control-wrpr">
                                                    <div className="progress-control-info">
                                                        <button type="button" className="btn btn--move-custom">
                                                            <span className="ico justify-start">
                                                                <DotsIcon />
                                                            </span>
                                                        </button>
                                                        <label className="check-wrapper gap--lg flex-auto">
                                                            <input type="checkbox" checked hidden />
                                                            <span className="check check--xs-2 check-custom-3"></span>
                                                            <div className="check-progress-text-wrpr">
                                                                <p className="check-progress-title-text">Subtask title</p>
                                                                <p className="check-progress-date-text">Nov 01,2024</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="progress-control-actions">
                                                        <a href="#" className="btn btn-link color-secondary">
                                                            <span className="ico"><EditIcon /></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab" data-id="files">
                            <div className="block-container"></div>
                        </div>
                        <div className="tab" data-id="comments">
                            <div className="block-container"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

};

export default TaskModal;
