import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const messages = {
    success: "Operation completed successfully!",
    error: "Something went wrong. Please try again."
};

const ToastProvider = () => {

    return (
        <ToastContainer
            hideProgressBar={true}
            autoClose={1000}
            theme="dark"
        />
    );

};

export default ToastProvider;
