import Filters from "@/components/common/Filters";


import AddIcon from "@/assets/img/ico-add.svg?react";
import StarsIcon from "@/assets/img/ico-stars.svg?react";
import MemberCard from "@/components/cards/MemberCard";
import Select from "@/components/common/Select";

const Team = () => {

    const teamsOptions = [{ label: "All Teams",
        value: "all" }, { label: "Option 1",
        value: "1" }, { label: "Option 2",
        value: "2" }, { label: "Option 3",
        value: "3" }];
    const rolesOptions = [{ label: "All Roles",
        value: "all" }, { label: "Option 1",
        value: "1" }, { label: "Option 2",
        value: "2" }, { label: "Option 3",
        value: "3" }];
    return (
        <div>
            <div className="rectangle-block-containers block-container-fullpage tabs">
                <div className="block-container navigation-block">
                    <div className="heading--row">
                        <div className="heading-option gap-12">
                            <div className="flex items-center flex-wrap gap-8">
                                <Select
                                    options={teamsOptions}
                                />
                                <Select
                                    options={rolesOptions}
                                />
                            </div>
                            <div className="form-group input--md input--icon-left">
                                <div className="input-wrapper">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="10.137" cy="10.137" r="6.47345" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M18.3365 18.3365L14.7141 14.7141" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <input className="input input--solid-3" type="text" placeholder="Search..." />
                                </div>
                            </div>

                        </div>
                        <div className="heading-option">

                            <div className="flex-controls gap--xs">
                                <Filters />
                                <button type="button" className="btn btn--secondary-advance-1 btn--md rounded-full">
                                    <span className="ico"><StarsIcon /></span>
                                    <span>Ask Your COO</span>
                                </button>
                                <a href="#" className="btn btn--primary-advance-1 ico--md rounded-full btn--dop-class">
                                    <span className="ico"><AddIcon /></span>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-container page-results overflow-auto">

                    <div className="row gutters-4">
                        {Array.from({ length: 10 }).map((_, index) => <div key={index} className="col-12 col-sm-12 col-lg-4 col-xl-3 flex flex-col">
                            <MemberCard />
                        </div>)}

                    </div>
                </div>


            </div>
        </div>
    );

};

export default Team;
