import React, { Fragment, ReactNode } from "react";
import Dropdown, { DropdownProps } from "./Dropdown";
import ChevronDownIcon from "@/assets/img/ico-chevron-down.svg?react";
import DotsIcon from "@/assets/img/ico-dots.svg?react";


export interface DropdownActionsProps extends Omit<DropdownProps, "children" | "button"> {
    button?: ReactNode
    actions: {
        fn: () => void,
        label: ReactNode,
        icon: ReactNode
    }[]
    variant?: "primary" | "secondary"
}

const DropdownActions = ({ actions, variant = "primary", ...props }: DropdownActionsProps) => {
 
    const button = {
        primary:
            <button type="button" className="btn btn--square btn--xs btn--custom-1-trsp rounded-full max-w-max dropdown-btn dropdown-btn--burger" data-role="button">
                <span className="ico"><DotsIcon /></span>
            </button>,
        secondary:
            <button type="button" className="btn btn--xs btn--custom-1 rounded-full max-w-max dropdown-btn" data-role="button">
                <span>Actions</span>
                <span className="ico">
                    <ChevronDownIcon />
                </span>
            </button>


    };

    return (
        <Dropdown
            {...props}
            bodyClassName="dropdown__table"
            button={props.button
                ? props.button
                : button[variant]}
        >
            <div className="dropdown-block">
                <div className="dropdown-block--body">
                    <ul className="dropdown-list">
                        {actions.map((action, index) => <Fragment key={index}>
                            <li className="dropdown-list--item">
                                <button onClick={e => { e.stopPropagation(); action.fn() }} className="btn btn--xs btn-drp-link">
                                    {action.icon && <span className="ico">{action.icon}</span>}
                                    <span className="info">{action.label}</span>
                                </button>
                            </li>
                            {index < actions.length - 1 &&
                                <li className="dropdown-list--item">
                                    <div className="btn btn--xs drp-list-wrapp-separator">
                                        <hr className="drp-list-separator" />
                                    </div>
                                </li>
                            }
                        </Fragment>)}
                    </ul>
                </div>
            </div>
        </Dropdown>
    );

};

export default DropdownActions;
