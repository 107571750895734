import DropdownActions from "@/components/common/DropdownActions";

import userImage from "@/assets/img/img-user-1.png";

import MailCharIcon from "@/assets/img/ico-mail-char.svg?react";
import MapPinIcon from "@/assets/img/ico-map-pin.svg?react";
import PeopleIcon from "@/assets/img/ico-people-group.svg?react";
import AddIcon from "@/assets/img/ico-add.svg?react";
import PhoneIcon from "@/assets/img/ico-phone.svg?react";
import NextProjectIcon from "@/assets/img/ico-proj-next.svg?react";
import UserIcon from "@/assets/img/ico-user-2.svg?react";
import { ColumnDef } from "@tanstack/react-table";
import Table from "@/components/common/Table";
import { Link } from "react-router";

interface UsersTabProps {
    isGridView: boolean
    isTableView: boolean
}

interface User {
    userName: string
    email: string
    role: string
    teams: string[]
    projects: number
    status: string
    actions: string
    photo:string
}

const UsersTab = ({ isGridView, isTableView }: UsersTabProps) => {

    const data: User[] = [{
        userName: "Cameron Williamson",
        photo: userImage,
        email: "kenzi.lawson@example.com",
        role: "Senior Designer",
        teams: ["Concept Design Team"],
        projects: 8,
        status: "Active",
        actions: "Edit Info"
    }];

    if (isGridView) {

        return (
            <div className="row gutters-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-col">
                    <div className="card-widget card-widget-product w-full h-full">
                        <div className="card-widget-body">
                            <div className="card-details-wrpr">
                                <div className="card-details--item">
                                    <div className="card-details--head">
                                        <div className="user-option-block user-option-block--sm">
                                            <div className="user-block-relatives">
                                                <div className="ico ico--lg user-block-img-wrpr rounded-full">
                                                    <img src={userImage} alt="user" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-details-actions">
                                            <div className="pill-custom pill--custom-11 pill--xs">
                                                <span>Occupied</span>
                                            </div>
                                            <DropdownActions actions={[]} />
                                        </div>
                                    </div>
                                    <div className="card-details--body">
                                        <div className="card-details-heading">
                                            <p className="heading user-block-title text--xl">
                                                Team Member Name 1
                                            </p>
                                            <p className="user-block--role text--md">Lead Designer</p>
                                        </div>

                                        <ul className="project-list user-noname-desk contacts-list">
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <PeopleIcon />
                                                    </span>
                                                    <p className="project-variable--text">Concept Design Team</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <MapPinIcon />
                                                    </span>
                                                    <p className="project-variable--text">Los Angeles, CA</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <MailCharIcon />
                                                    </span>
                                                    <p className="project-variable--text">alma.lawson@example.com</p>
                                                </div>
                                            </li>
                                            <li className="project-list-item">
                                                <div className="project-variable-wrpr">
                                                    <span className="ico project-variable--ico">
                                                        <PhoneIcon />
                                                    </span>
                                                    <p className="project-variable--text">(217) 555-0113</p>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="card-details--item">
                                    <div className="card-details--body">
                                        <div className="card-details--projects-info-wrpr">
                                            <div className="card-details--head card-details--project-row">
                                                <ul className="counter-projects-list">
                                                    <li className="counter-project-item">
                                                        <div className="counter-project-text">
                                                            <div className="pill-custom pill--xs">1</div>
                                                            <p className="counter-project-value">Active Tasks</p>
                                                        </div>
                                                    </li>
                                                    <li className="counter-project-item">
                                                        <div className="counter-project-text">
                                                            <div className="pill-custom pill--xs">3</div>
                                                            <p className="counter-project-value">Completed</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-details--projects-info--body">
                                                <ul className="card-details--projects-info-list">
                                                    <li className="card-details--project-info--item">
                                                        <a href="#" className="btn btn--project-row">
                                                            <div className="event-project-status --info-1"></div>
                                                            <div className="project-titled-wrpr">
                                                                <div className="project-info-wrapper-block">
                                                                    <div className="project-info-item-block --only">
                                                                        <p className="project-title-2">Design concept revision</p>
                                                                        <p className="user-role--date">
                                                                            <span className="date-variable-text">Due:</span>
                                                                            <span>Nov 10, 2024</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn--project-row--ico ico">
                                                                <NextProjectIcon />
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="card-details--actions">
                                            <div className="card-details--action-item">
                                                <a href="#" className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span>View Task</span>
                                                </a>
                                            </div>
                                            <div className="card-details--action-item">
                                                <Link to={"42/42"} className="btn btn--custom-1 btn--md rounded-full btn-detail-action">
                                                    <span className="ico"><UserIcon /></span>
                                                    <span>Profile</span>
                                                </Link>
                                            </div>
                                            <div className="card-details--action-item --auto-action">
                                                <a href="#" className="btn btn--primary-advance-1 btn--md btn--square rounded-full btn-detail-action">
                                                    <span className="ico"><AddIcon /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
    const columns: ColumnDef<User>[] = [{
        header: "User Name",
        accessorKey: "userName",
        cell: ({ row }) => <div className="table-user-info">
            <span className="ico ico-ava ico-ava-24">
                <img src={row.original.photo} alt="User Avatar" />
            </span>
            <div className="table-user-info-texts">
                <a href="#" className="btn btn-link color-secondary tdu">
                    <span className="text--md font-400">{row.original.userName}</span>
                </a>
            </div>
        </div>

    },
    {
        header: "Email",
        accessorKey: "email",
        cell: ({ row }) => <p className="color-control-3">{row.original.email}</p>
    },
    {
        header: "Role",
        accessorKey: "role",
        cell: ({ row }) => <div className="pill-custom pill--custom-3 pill--sm">
            <span>{row.original.role}</span>
        </div>

    },
    {
        header: "Team(s)",
        accessorKey: "teams",
        cell: ({ row }) => <a href="#" className="btn btn-link color-secondary tdu">
            <span className="text--md font-400">{row.original.teams.join(", ")}</span>
        </a>

    },
    {
        header: "Projects",
        accessorKey: "projects",
        cell: ({ row }) => <p className="font-500">{row.original.projects}</p>
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => <div className="pill-custom pill--custom-1 pill--sm">
            <span>{row.original.status}</span>
        </div>

    },
    {
        header: "Actions",
        accessorKey: "actions",
        enableSorting: false,
        cell: ({ row }) => <DropdownActions variant="secondary" actions={[]} />

    }];

    return <Table columns={columns} data={data} selectable />;

};

export default UsersTab;
