import clsx from "clsx";
import React, { useState, useEffect } from "react";
import Button from "./Button";

type ChoiceOption = {
    label: string;
    value: string;
};

type MultiChoiceSelectorProps = {
    options: ChoiceOption[];
    maxSelection?: number;
    onChange?: (selected: string[]) => void;
    value: string[];
};

const MultiChoiceSelector: React.FC<MultiChoiceSelectorProps> = ({
    options,
    maxSelection = 6,
    onChange,
    value = []
}) => {

    const [selectedChoices, setSelectedChoices] = useState<string[]>(value);
    const canSelect = selectedChoices.length < maxSelection;
    useEffect(() => {

        setSelectedChoices(value);

    }, [value]);

    const handleSkillClick = (skillValue: string) => {

        if (selectedChoices.includes(skillValue)) {

            const updatedSkills = selectedChoices.filter((s) => s !== skillValue);
            setSelectedChoices(updatedSkills);
            onChange?.(updatedSkills);

        } else if (canSelect) {

            const updatedSkills = [...selectedChoices, skillValue];
            setSelectedChoices(updatedSkills);
            onChange?.(updatedSkills);

        }

    };

    return (
        <div className="form-group input--md">
            <label className="label color-default fw-500">
                Select user personal skills ({maxSelection} max)
            </label>
            <div className="row-group flex-wrap gap--md">
                {options.map(({ label, value }) => <Button
                    type="button"
                    key={value}
                    disabled={!canSelect && !selectedChoices.includes(value)}
                    onClick={() => handleSkillClick(value)}
                    className={clsx("btn btn--md rounded-full btn--outline-color-control-8", { "btn--secondary": selectedChoices.includes(value) })}
                >
                    {label}
                </Button>)}
            </div>
        </div>
    );

};

export default MultiChoiceSelector;
