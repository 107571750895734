import SearchIcon from "@/assets/img/ico-search.svg?react";

const Search = () => {

    return (
        <a href="#" className="btn btn--custom-1 btn--md rounded-full btn--square">
            <span className="ico"><SearchIcon /></span>
        </a>
    );

};

export default Search;
