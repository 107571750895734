import { ModalProps } from "@/components/modals/Modal";
import NewEntityModal from "@/components/modals/NewEntityModal/NewEntityModal";
import { useNewEntityModal } from "@/hooks/useNewEntityModal";
import UserData from "./components/UserData";

interface NewUserModal extends ModalProps {

}

const completeActionButtons = [{ text: "Add Project",
    handler: () => { } }, { text: "Add Task",
    handler: () => { } }];

const NewUserModal = ({ ...props }: NewUserModal) => {

    const { currentStage, handleCompleteClick, handleContinueClick, handleCancel } = useNewEntityModal();

    return (
        <NewEntityModal
            currentStage={currentStage}
            props={props}
            completeTitle="New User Created!"
            completeDescription="Congratulations on successfully creating a new user! You can now start managing their project, assign tasks or return to the home page"
            completeActionButtons={completeActionButtons}
            handleCancel={handleCancel}
            handleContinueClick={handleContinueClick}
        >
            <UserData handleCancel={handleCancel} handleCompleteClick={handleCompleteClick} />
        </NewEntityModal>
    );

};

export default NewUserModal;
