import clsx from "clsx";

import CheckmarkIcon from "@/assets/img/checkmark-icon.svg?react";

export interface Step<T> {
    readonly id: T
    readonly label: string
}
interface StepsNavigationProps<T> {
    steps: readonly Step<T>[];
    activeStep: T;
    setActiveStep: (stepId: T) => void;
}

const StepsNavigation = <T, >({
    steps,
    activeStep,
    setActiveStep
}: StepsNavigationProps<T>) => {

    const activeStepIndex = steps.findIndex(({ id }) => activeStep === id);
    return (
        <div className="tabs-navigation">
            <div className={clsx("tabs-navigation-list --list-2")}>
                {steps.map(({ id, label }, index) => <button
                    type="button"
                    key={index}
                    // onClick={index < activeStepIndex
                    //     ? () => setActiveStep(id)
                    //     : null}
                    onClick={() => setActiveStep(id)}
                    className={clsx("btn btn--md tab-custom-btn-2", {
                        "active-tab": activeStep === id
                    })}
                >
                    {index <= activeStepIndex &&
                            <span className="tab-indicator">
                                {activeStep !== id && <span className="ico"><CheckmarkIcon /></span>}
                            </span>
                    }
                    <span>{label}</span>
                </button>)}
            </div>
        </div>
    );

};

export default StepsNavigation;
