import { toCurrency } from "@/utils";
import { ArcElement, ChartData, Chart as ChartJS, ChartOptions, Legend, Tooltip } from "chart.js";
import { useId } from "react";
import { Doughnut } from "react-chartjs-2";
import { DoughnutRoundedCornersPlugin, DoughnutTotalPlugin, htmlLegendPlugin } from "./plugins";

ChartJS.register(ArcElement, Tooltip, Legend);


interface DoughnutChartProps {
    data: {
        data: number[]
        labels: string[]
        format?: (val: number) => string
    },
    total: {
        label: string
        value?: number
        format?: (val: number) => string
    }
    variant?: "primary" | "secondary",

}

const DoughnutChart = ({ data, total, variant = "primary" }: DoughnutChartProps) => {

    const id = useId();
    const containerID = `legend-container-${id}`;

    const chartOptions: ChartOptions<"doughnut"> = {
        animation: { duration: 0 },
        cutout: "85%",
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
            htmlLegend: {
                containerID
            }
        }
    };

    const COLORS = ["#177E89", "#891719", "#927B65", "#174C89"];
    const TOTAL_COLOR = "#ECECEC";
    const getRest = (datasets: number[], total: number) => total - datasets.reduce((acc, curr) => acc + curr, 0);

    const style = {
        primary: {
            borderWidth: 0
        },
        secondary: {
            borderWidth: 5,
            borderRadius: 360
        }
    };

    const chartData: ChartData<"doughnut", number[], string> = {
        labels: data.labels,
        datasets: [{
            label: "# of Votes",
            data: [...data.data, ...total.value
                ? [getRest(data.data, total.value)]
                : []],
            backgroundColor: [...COLORS.slice(0, data.data.length), TOTAL_COLOR],
            ...style[variant]
        }]
    };


    return (
        <div className="chart-row-wrpr">
            <div className="chart-row-wrpr-chart">
                <Doughnut
                    className="doughnut-chart--js-3 chart-style"
                    data={chartData}
                    options={chartOptions}
                    plugins={
                        [DoughnutTotalPlugin({ ...total,
                            format: total?.format }), htmlLegendPlugin(total.value, data?.format), ...variant === "primary"
                            ? [DoughnutRoundedCornersPlugin]
                            : []]}
                />
            </div>
            <div id={containerID} className="legend legend-not-last" />
        </div>
    );

};

export default DoughnutChart;
